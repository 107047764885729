<div class="d-flex justify-content-between" style="background-color: #ffffff;">
  <div class=" h-100 col-xl-6 col-sm-12 col-xs-12">

    <div>
      <div class="h3 text-center font-weight-bold" style="color: black; margin-top: 150px;">
        Zaloguj się
      </div>
      <p class="text-center">Zaloguj się aby złożyć zamówienie.</p>
    </div>

    <div class="text-center mt-3">

      <form (keydown.enter)="HandleLogin()">

        <label for="localization" class="border email_input">
          <img src="/assets/img/Email.svg" alt="" class="">
          <input name="email"  id="email" type="text" placeholder="Podaj adres email" class="border-0 h-100" required>
        </label>

        <label for="localization" class="border email_input" style="margin-top: 8px;">
          <img src="/assets/img/Light.svg" alt="" class="">
          <input name="password" id="password" type="password" placeholder="Podaj hasło" class="border-0 h-100" NgModel required>
        </label>

      </form>

    </div>

    <div class="mt-2 mb-2" style="margin-left: 20%;">
      <input type="checkbox" class="remember_me"> Zapamiętaj mnie
    </div>

    <div class="text-center m-w-100">
      <button class="login_button" (click)="HandleLogin()">
        Zaloguj się
      </button>
    </div>

    <div class="text-center a mt-4 forget_pass">
      Zapomniałeś hasła ?
    </div>

    <div class="divider my-4" style="width: 50%; margin-left:auto; margin-right: auto;">
      <div class="divider-text">
        lub
      </div>
    </div>

    <div class="text-center">
      Nie posiadasz konta ?
      <a href="/Register" style="color: #87E1F9;">
        Zarejestruj się
      </a>
    </div>

  </div>

  <div class="image col-sm-0 col-xl-6">
  </div>
</div>
