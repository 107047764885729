import { Component, OnInit } from '@angular/core';
import { Hero } from './hero';
import {Router} from '@angular/router';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  hero: Hero = {
    id: 1,
    name: 'hero hero hero '
  };

  constructor(private route:Router) { }

  ngOnInit(): void {
  }

  HandleSearch () {
    const search = (document.getElementById('service') as HTMLInputElement).value;
    
    this.route.navigate(['/Oferty/Kategoria/' + search]);
  }

}
