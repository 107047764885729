import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-privacy-policy',
  templateUrl: './template-privacy-policy.component.html',
  styleUrls: ['./template-privacy-policy.component.scss']
})
export class TemplatePrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.title = 'Polityka prywatności - Pickmode';
  }

}
