<app-header></app-header>
<div style="padding-top:180px;" ></div>
<main>
    <div class="card" style="margin-left: auto; margin-right:auto;justify-content: space-between; display: flex;">
        <div>
            Hej !
            <p>
                Nazywam sie Magdalena Domagała i wprowadzę Cie do świata, którego jeszcze nie znasz ! Do Aplikacji stworzonej właśnie dla Ciebie! To Ty, Twoja firma, a przedewszystkim bezcenny czas jest dla nas priorytetem ! Dlatego z nami : 
                
                -> pracownicy mogą zgłaszać kończący sie asortyment, produkty itp. poprzez dostęp do zakładki "stany magazynowe".
                <br>
                -> masz możliwość wystawienia oceny klientowi!
                <br>
                -> Twoje pieniądze są najważniejsze dlatego trafiają bezpośrednio na Twoje konto bankowe bez przetrzymywania środków.
                <br>
                -> Oraz wiele opcji, które będą dla Ciebie czymś czego nie ma konkurencja.
                <br>
                <br>
                Twoje szczęście jest naszym szczęściem  Pickmode Magdalena Domagała
            </p>

            <img src="/assets/img/pexels-david-geib-32687321.png" alt="" class="img">
        
        </div>
    </div>
</main>