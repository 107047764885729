<app-header></app-header>

<div style="padding-top: 180px;"></div>

<div class="loading-screen" id="loading" [style.display]="LoadingDisplay" style="text-align: center;">
    <img src="/assets/img/Spinner-0.8s-290px.gif" alt="">
    <div style="padding-top: 180px;"></div>
</div>

<app-profile-edition></app-profile-edition>

<app-my-opinions></app-my-opinions>

<div style="padding-top: 50px;"></div>

<app-orders></app-orders>

<div style="padding-top: 50px;"></div>

<div class="card p-2">
    <div>
        <button  class="btn btn-danger" (click)="Logout ()" >
            Wyloguj się
        </button>
    </div>
</div>

<div style="padding-top: 50px;"></div>