<app-header></app-header>
<div class="empty-space" style="padding-top: 200px;"></div>

<div class="loading-screen" id="loading" [style.display]="LoadingDisplay" style="text-align: center;">
    <img src="/assets/img/Spinner-0.8s-290px.gif" alt="">
    <div class="empty-space" style="padding-top: 200px;"></div>
</div>

<app-single-item-header title="{{ item.title }}" phone="{{ item.telephone }}" adres="{{ item.adres }}" image="{{ image }}" [comments]="item.comments"></app-single-item-header>
<app-single-item-content description="{{ item.description }}" id="{{ item.id }}"></app-single-item-content>
<app-single-item-opinions [comments]="item.comments"></app-single-item-opinions>
<app-footer></app-footer>

