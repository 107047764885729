<div class="container-fluid recommended-items">
  <div class="container">

    <div class="row">
      <div class="col d-flex  align-items-center">
        <h3 class="pe-5 m-0">Fryzjer Poznań , Grunwald (344)</h3>
      </div>
    </div>

    <div class="row pt-5 mt-3 d-flex flex-wrap align-items-center justify-content-between">
      <div class="col-12 col-lg-4"> <app-item ></app-item></div>
      <div class="col-12 col-lg-4"> <app-item ></app-item></div>
      <div class="col-12 col-lg-4"> <app-item ></app-item></div>
      <div class="col-12 col-lg-4"> <app-item ></app-item></div>
      <div class="col-12 col-lg-4"> <app-item ></app-item></div>
      <div class="col-12 col-lg-4"> <app-item ></app-item></div>
    </div>

  </div>

</div>
