import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-recommended-items',
  templateUrl: './recommended-items.component.html',
  styleUrls: ['./recommended-items.component.scss']
})
export class RecommendedItemsComponent implements OnInit {

  recommendedItems: any;
  location = localStorage.getItem('city');
  image: string;

  constructor() { }

  ngOnInit(): void {

    

    if (localStorage.getItem('city')) {

      axios.get(environment.API_URL + 'Offer/City/' + localStorage.getItem('city'))
      .then(function (response) {
        this.recommendedItems = response.data.success;
        console.log(this.recommendedItems);
      
        // if array is bigger than 6, then slice it to 6
        if (this.recommendedItems.length > 6) {
          this.recommendedItems = this.recommendedItems.slice(0, 6);
        }

        const baseUrl = environment.API_URL.substring(0, environment.API_URL.length - 4);

        this.recommendedItems.forEach(element => {
          element.image = baseUrl + 'OfferImage/' + element.image.image;
          console.log(element);
        });


      }.bind(this))
      .catch(function (error) {
        console.log(error);
      }.bind(this));

    } else {

      axios.get(environment.API_URL + 'Offer')
      .then(function (response) {
        this.recommendedItems = response.data;
        console.log(this.recommendedItems);
      
        // if array is bigger than 6, then slice it to 6
        if (this.recommendedItems.length > 6) {
          this.recommendedItems = this.recommendedItems.slice(0, 6);
        }

        const baseUrl = environment.API_URL.substring(0, environment.API_URL.length - 4);

        this.recommendedItems.forEach(element => {
          element.image = baseUrl + 'OfferImage/' + element.image.image;
          console.log(element);
        });

      }.bind(this))
      .catch(function (error) {
        console.log(error);
      }.bind(this));

    }
  }

}
