<div class="container-fluid mt-5 mb-5 seo-content">

  <div class="container">
    <div class="row">
      <div class="col">
        <h3 class="text-dark text-center">Znajdź specjalistę z pickmode  w Twoim mieście</h3>
        <p class="text-center">Przeczytaj artykuły z poradami specjalistów</p>
      </div>
    </div>
  </div>

  <div class="container mt-3">
    <div class="row">
      <div class="col"></div>

      <div class="col-10 d-flex flex-wrap  flex-lg-nowrap justify-content-center gap-lg-5">

        <div class="d-flex justify-content-center text-center text-lg-start  col-12 col-lg-3">
          <ul class="list-unstyled">
            <li>Gliwice</li>
            <li>Radom</li>
            <li>Zielona Góra</li>
            <li>Poznań</li>
            <li>Kielce</li>
          </ul>
        </div>

        <div class="d-flex justify-content-center text-center text-lg-start  col-12 col-lg-3">
          <ul class="list-unstyled">
            <li>Warszawa</li>
            <li>Łódź</li>
            <li>Gdańsk</li>
            <li>Częstochowa</li>
            <li>Bydgoszcz</li>
          </ul>
        </div>

        <div class="d-flex justify-content-center text-center text-lg-start  col-12 col-lg-3">
          <ul class="list-unstyled">
            <li>Wrocław</li>
            <li>Gdynia</li>
            <li>Katowice</li>
            <li>Opole</li>
            <li>Szczecin</li>
          </ul>
        </div>

        <div class="d-flex justify-content-center text-center text-lg-start  col-12 col-lg-3">
          <ul class="list-unstyled">
            <li>Sosnowiec</li>
            <li>Toruń</li>
            <li>Rzeszów</li>
            <li>Olsztyn</li>
            <li>Kraków</li>
          </ul>
        </div>

      </div>
      <div class="col"></div>

    </div>
  </div>

  <div class="container mt-5">

    <div class="row d-flex justify-content-center">
      <div class="col-lg-4">
        <h3 class="text-dark text-center">Skup się na tym, co ważne.
          My zajmiemy się resztą...</h3>
      </div>
    </div>

    <div class="row d-flex justify-content-center mt-5">
      <div class="col-lg-4 text-center text-lg-start">
        <p>Chcesz umówić się na wizytę do fryzjera, barbera, stylistki paznokci lub salonu masażu w okolicy? Szukasz miejsca, w którym specjaliści zadbają o Twoją brodę, brwi lub zrobią masaż tkanek głębokich?        </p>
      </div>
      <div class="col-lg-4 text-center text-lg-start">
        <p>Pickmode to darmowa aplikacja do rezerwacji, dzięki której z łatwością znajdziesz wolny termin u specjalisty i wygodnie umówisz się na wizytę. Bez dzwonienia - rezerwujesz o każdej porze i z dowolnego miejsca.  Odkrywaj nowe miejsca w swojej okolicy i rezerwuj od razu z Pickmode
        </p>
      </div>
    </div>

  </div>


</div>
