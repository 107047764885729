<app-header></app-header>
<div style="padding-top:180px;" ></div>
<div class="card text-dark w-75 p-5" style="margin-left: auto; margin-right:auto;">
    <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse posuere est eu metus consectetur tincidunt. Phasellus in euismod orci. Morbi magna turpis, varius vel est id, mollis interdum enim. Fusce molestie laoreet nulla sed tristique. Curabitur tortor tellus, ultrices vitae molestie sit amet, condimentum sit amet risus. Aliquam sollicitudin sollicitudin sagittis. Mauris imperdiet sapien justo, non sollicitudin risus commodo ac. Phasellus nec pellentesque sem. In fermentum ipsum neque, non dapibus ex efficitur et. Duis posuere erat at erat tempus lacinia.
    </p>
    <p>
        Aenean lacinia risus est, tempor lobortis ex tincidunt vel. Pellentesque pulvinar, odio sed sodales fringilla, est arcu aliquam est, venenatis consequat enim leo sed metus. Vivamus massa dui, tincidunt ut est eget, ullamcorper ornare diam. Aenean sed lectus orci. Nam non dolor eu ipsum eleifend efficitur rutrum elementum lectus. Nunc a neque vitae sem vehicula blandit et sed justo. Nullam convallis sapien ac sodales rhoncus. Curabitur eget turpis odio. Fusce aliquet sapien ac varius dapibus. Quisque sollicitudin fermentum scelerisque.
    </p>
    <p>
        Aliquam faucibus sem et tincidunt commodo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean elit ligula, pulvinar ut pulvinar feugiat, accumsan at risus. Vivamus id porta enim. Proin laoreet eget magna vitae laoreet. Aliquam molestie at urna non fermentum. Aenean dapibus nec neque sed lobortis. Nulla sed turpis nec turpis varius ullamcorper. Aliquam vitae dolor molestie, aliquet quam in, luctus magna.
    </p>
    <p>
        Quisque scelerisque pretium odio, at vehicula ex scelerisque a. Aliquam luctus magna quis arcu sodales consectetur. Curabitur ut blandit ex. Donec tempor ligula tellus, at sagittis massa vulputate ut. Nullam venenatis orci in arcu eleifend, ut eleifend diam volutpat. Nam non dignissim dolor, eget lacinia diam. Mauris tincidunt elementum dignissim. Suspendisse eu convallis felis. In sit amet felis vitae nulla sodales rhoncus ut non mi. Curabitur vel faucibus ipsum. Phasellus tempor erat quam, non varius lectus tempus quis.
    </p>
    <p>
        Aenean lacinia risus est, tempor lobortis ex tincidunt vel. Pellentesque pulvinar, odio sed sodales fringilla, est arcu aliquam est, venenatis consequat enim leo sed metus. Vivamus massa dui, tincidunt ut est eget, ullamcorper ornare diam. Aenean sed lectus orci. Nam non dolor eu ipsum eleifend efficitur rutrum elementum lectus. Nunc a neque vitae sem vehicula blandit et sed justo. Nullam convallis sapien ac sodales rhoncus. Curabitur eget turpis odio. Fusce aliquet sapien ac varius dapibus. Quisque sollicitudin fermentum scelerisque.
    </p>
    <p>
        Aliquam faucibus sem et tincidunt commodo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean elit ligula, pulvinar ut pulvinar feugiat, accumsan at risus. Vivamus id porta enim. Proin laoreet eget magna vitae laoreet. Aliquam molestie at urna non fermentum. Aenean dapibus nec neque sed lobortis. Nulla sed turpis nec turpis varius ullamcorper. Aliquam vitae dolor molestie, aliquet quam in, luctus magna.
    </p>
    <p>
        Quisque scelerisque pretium odio, at vehicula ex scelerisque a. Aliquam luctus magna quis arcu sodales consectetur. Curabitur ut blandit ex. Donec tempor ligula tellus, at sagittis massa vulputate ut. Nullam venenatis orci in arcu eleifend, ut eleifend diam volutpat. Nam non dignissim dolor, eget lacinia diam. Mauris tincidunt elementum dignissim. Suspendisse eu convallis felis. In sit amet felis vitae nulla sodales rhoncus ut non mi. Curabitur vel faucibus ipsum. Phasellus tempor erat quam, non varius lectus tempus quis.
    </p>
</div>