<app-header></app-header>
<div style="padding-top:180px;" ></div>
<div class="card text-dark w-75 p-5" style="margin-left: auto; margin-right:auto;">
    <div class="legalContent" data-v-221eafb6="">

        <h1><strong>Polityka prywatności - PickMode</strong></h1>
        <h2><strong>Jakie informacje osobiste są gromadzone i wykorzystywane przez PickMode?</strong></h2>
        <p>Korzystający z naszego portalu pozostają anonimowi tak długo, aż sami nie zdecydują inaczej. Wynikające z ogólnych zasad połączeń realizowanych w Internecie informacje zawarte w logach systemowych (np. adres IP) są przez PickMode wykorzystywane w celach technicznych, związanych z administracją naszymi serwerami. Poza tym adresy IP są wykorzystywane do zbierania ogólnych, statystycznych informacji demograficznych (np. o regionie, z którego następuje połączenie). Zapisanie się do PickMode wymaga wypełnienia formularza, w którym należy podać informacje umożliwiające skontaktowanie się z użytkownikiem (np. adres e-mail) oraz informacje demograficzne (np. płeć). Informacje podane w formularzu są przez nas wykorzystywane do niezbędnych kontaktów z naszymi użytkownikami oraz w celu lepszego dostosowywania prezentowanych przez nas treści do ich potrzeb i zainteresowań.</p>
        <h2><strong>Jaka jest polityka PickMode dotycząca plików „cookies”?</strong></h2>
        <p>Twoja przeglądarka internetowa może przechowywać pliki tekstowe (ang. „cookies”) na dysku Twojego komputera. W plikach „cookies” znajdują się informacje niezbędne do prawidłowego funkcjonowania portalu, w szczególności tych wymagających autoryzacji. Zawartość plików cookies nie pozwala na identyfikację użytkownika. Za pomocą plików cookies nie są przetwarzane lub przechowywane dane osobowe.</p>
        <p>PickMode przechowuje pliki cookies na komputerach użytkowników w celu:</p>
        <ul>
            <li>utrzymania sesji użytkownika (po zalogowaniu), dzięki której użytkownik nie musi na każdej stronie wpisywać nazwy użytkownika i hasła;</li>
            <li>lepszego dopasowania serwisów do potrzeb użytkowników;</li>
            <li>tworzenia statystyk oglądalności dla poprawienia funkcjonowania portalu;</li>
        </ul>
        <p>Istnieje możliwość takiego skonfigurowania Twojej przeglądarki internetowej, która spowoduje, że całkowicie zostanie wyłączona możliwość przechowywania plików cookies na dysku twardym Twojego komputera. Pamiętaj, że efektem takiej zmiany może być utrata możliwości korzystania z niektórych funkcji serwisu PickMode (logowanie, podgląd zdjęć itp.).</p>
        <h2><strong>Szczegółowe informacja na temat plików „cookies”</strong></h2>
        <p>System PickMode wykorzystuje tzw. pliki cookies (ciasteczka), tj. pliki tekstowe służące do zapisywania i przechowywania informacji o sposobie wykorzystywania Systemu przez użytkownika. Pliki cookies są zapisywane na urządzeniu użytkownika oraz przesyłane przez przeglądarkę internetową użytkownika do Systemu za każdym razem, gdy użytkownik z niego korzysta.</p>
        <p>PickMode wykorzystuje również informacje zawarte w logach systemowych (np. adres IP).</p>
        <p>PickMode wykorzystuje następujące rodzaje plików cookies:</p>
        <ul>
            <li>cookies sesyjne&nbsp;- automatycznie usuwane po zamknięciu przeglądarki internetowej;</li>
            <li>cookies trwałe&nbsp;– przechowywane są w urządzeniu przez określony czas;</li>
            <li>cookies własne&nbsp;- ustawiane przez System PickMode;</li>
            <li>cookies podmiotów trzecich&nbsp;- ustawiane przez inne serwisy niż System PickMode, np. Google Analytics, Facebook, itp.</li>
        </ul>
        <p>PickMode wykorzystuje informacje gromadzone przy użyciu plików cookies oraz pochodzące z logów systemowych w celu:</p>
        <ul>
            <li>dostosowania treści, wyglądu, rozmiaru oraz innych parametrów Systemu do konkretnego urządzenia i preferencji użytkownika;</li>
            <li>zbierania ogólnych, statystycznych informacji demograficznych (np. o regionie, z którego następuje połączenie).</li>
        </ul>
        <p>Pliki cookies wykorzystywane przez System PickMode nie pozwalają PickMode na identyfikację konkretnych użytkowników korzystających z Systemu.</p>
        <p>Korzystając z Systemu użytkownik wyraża zgodę na umieszczanie opisanych powyżej plików cookies w swoim urządzeniu.</p>
        <p>Użytkownik może usunąć lub zablokować instalację plików cookies za pośrednictwem zmiany ustawień przeglądarki internetowej. Szczegółowe informacje na temat możliwości modyfikacji ustawień przeglądarki, blokowania i filtrowania plików cookies można znaleźć pod adresem:&nbsp;<a href="http://www.aboutcookies.org/">http://www.aboutcookies.org/</a>&nbsp;lub&nbsp;<a href="http://www.cookiecentral.com/faq/">http://www.cookiecentral.com/faq/</a>.</p>
        <p>Usunięcie lub zablokowanie plików cookies może wpłynąć na dostępność funkcjonalności Systemu, prawidłowości wyświetlania Systemu oraz utratę preferowanych ustawień stron internetowych Systemu.</p>
        <h2><strong>W jaki sposób chronione są informacje?</strong></h2>
        <p>Formularz wypełniany przez użytkownika w trakcie zapisywania się do PickMode, a także sam proces autoryzacji (logowania) do naszych zasobów realizowany jest za pomocą kodowanych sesji, które istotnie zwiększą ochronę transmisji danych w sieci Internet. Dane takie jak hasła dostępu do profili są zaszyfrowane za pomocą jednokierunkowych algorytmów szyfrowania, uniemożliwiających ich późniejsze odszyfrowanie. Informacje przechowywane są i przetwarzane przez PickMode z zachowaniem odpowiednich środków bezpieczeństwa, spełniających wymagania polskiego prawa.</p>
        <h2><strong>Czy podane informacje osobiste są udostępniane innym podmiotom?</strong></h2>
        <p>PickMode zawiera lub będzie zawierał funkcje (np. recenzje), w których wprowadzane przez użytkownika treści (np. treść i podpis opinii) stają się publicznie dostępne. Użytkownik może publikować swoje treści w tych serwisach jedynie po dokonaniu autoryzacji i sam decyduje o tym, jak mają one być podpisywane.</p>
        <h2><strong>Prawo do wglądu, poprawienia i usunięcia informacji o użytkowniku</strong></h2>
        <p>PickMode udostępnia każdemu użytkownikowi stronę profilową, dostępną po autoryzacji. Strona ta pozwala na wgląd, modyfikację i usunięcie posiadanych przez PickMode danych osobowych o użytkowniku. W przypadku, gdyby użytkownik uznał takie rozwiązanie za niewystarczające, może zwrócić się pisemnie do PickMode.</p>
        <h2><strong>Prawo wyboru</strong></h2>
        <p>PickMode daje swoim użytkownikom możliwość wyboru, czy i w jakim zakresie chcą korzystać z naszych funkcji i udostępniać o sobie informacje. Większość naszych funkcji jest dostępna bez konieczności podawania informacji o sobie, jedynie niektóre funkcje wymagają zapisania się. Zapisanie się do PickMode jest dobrowolną decyzją użytkownika i może on w każdej chwili zrezygnować i usunąć swój profil.</p>

        <h2><strong>Pytania</strong></h2>
        <p>Naszym celem jest zapewnienie jak najdalej posuniętej ochrony. Rozwój technologii oraz rozwój oferty PickMode powodują, że nasza polityka ochrony prywatności może ulegać zmianom, o których będziemy informować na tych stronach.</p>
        <p>PickMode jest otwarty na wszelkie opinie, uwagi i pytania swoich użytkowników dotyczące zachowania poufności informacji. Prosimy o ich kierowanie do PickMode na adres e-mail:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>&nbsp;lub pisemnie listem tradycyjnym na adres PickMode.</p>
        <h2><strong>Dane osobowe</strong></h2>
        <p>Informacje na temat podstaw prawnych i celów przetwarzania przez PickMode danych osobowych Klientów znajdziesz w Załączniku nr 1 do Polityki prywatności „Podstawy prawne i cele przetwarzania przez PickMode danych osobowych Klientów”, a danych osobowych Partnerów w Załączniku nr 2 do Polityki prywatności „Podstawy prawne i cele przetwarzania przez PickMode danych osobowych Partnerów”.</p>
        <h2><strong>Załącznik nr 1</strong></h2>
        <h2><strong>Podstawy prawne i cele przetwarzania przez PickMode danych osobowych Klientów.</strong></h2>
        <ol>
            <li>PickMode przywiązuje szczególną wagę do poszanowania prywatności Klientów.</li>
            <li>Klient jest zobowiązany do aktualizacji swoich danych osobowych, w szczególności znajdujących się na jego Profilu, Koncie albo w Systemie.</li>
            <li>Klient, zamieszczając swoje dane w Systemie:<ol>
                    <li>wyraża zgodę na to, że jego imię oraz zdjęcie udostępnione przez niego w Profilu będą dostępne dla wszystkich użytkowników sieci Internet;</li>
                    <li>Partnerzy będą kontaktować się z nim bezpośrednio na podany przez niego podczas Rejestracji adres e-mail lub nr telefonu.</li>
                </ol>
            </li>
            <li>Klient przy tworzeniu Konta może wyrazić odpowiednie zgody dotyczące przetwarzania danych osobowych i komunikacji elektronicznej w innych celach nich wykonanie umowy zawartej z PickMode.</li>
            <li>Istotą Systemu oraz Aplikacji dla Biznesu jest umożliwienie Partnerom świadczenia Usług na rzecz Klientów oraz umożliwienie Klientom korzystania z Usług Partnerów, promując jednocześnie oferowane usługi przez PickMode oraz Usługi Partnerów. W związku z tym PickMode i Partner przetwarzają dane osobowe Klientów we własnych celach, zatem są odrębnymi administratorami ich danych osobowych. Gdy Klient za pomocą Aplikacji dokonuje Rezerwacji usługi Partnera:<ol>
                    <li>dochodzi do zawarcia umowy między Klientem a Partnerem o świadczenie przez Partnera Usług na rzecz Klienta,</li>
                    <li>Klient może w Aplikacji dodatkowo udzielić Partnerowi zgód na przetwarzanie przez niego danych osobowych (na komunikację elektroniczną, na profilowanie) w innych celach niż w celu wykonania umowy z Klientem,</li>
                    <li>PickMode udostępnia Partnerowi za pomocą Aplikacji dane osobowe Klienta potrzebne do świadczenia przez niego Usług na rzecz tego Klienta,</li>
                    <li>Partner staje się administratorem danych osobowych takiego Klienta, w związku z czym zobowiązany jest do przestrzegania zasad określonych w RODO i ponosi za to odpowiedzialność wynikającą z przepisów.</li>
                </ol>
            </li>
            <li>Z uwagi na fakt, że istotą funkcjonowania Systemu jest dokonywanie Rezerwacji Usług, która wiąże się z koniecznością potwierdzenia Rezerwacji i terminu wykonania Usługi, Klient po dokonaniu Rezerwacji będzie otrzymywał za pośrednictwem Systemu, korespondencję e- mail/push lub wiadomości sms zawierające ww. potwierdzenia. Potwierdzenia te nie zawierają treści marketingowych ani handlowych, a jedynie informacje dotyczące Rezerwacji.</li>
            <li>Gdy Partner będzie korzystać z narzędzi dostępnych w ramach usług PickMode (m.in. kalendarz wizyt, przypomnienia, marketing) – PickMode będzie w niezbędnym zakresie w imieniu Partnera przetwarzać dane Klienta na zasadach określonych w zawartej z Partnerem – zgodnie z art. 28 RODO – umowie powierzenia przetwarzania danych osobowych, a Partner nadal będzie administratorem jego danych osobowych.</li>
            <li>Cele w jakich Partner będzie mógł przetwarzać dane Klienta będą uzależnione od tego, czy i jakich zgód Klient udzielił Partnerowi na przetwarzanie jego danych osobowych, czy tych zgód nie wycofał lub nie zgłosił sprzeciwu wobec przetwarzania przez Partnera jego danych osobowych.</li>
            <li>Szczegółowe informacje na temat podstaw prawnych i celów przetwarzania przez PickMode danych osobowych Klientów dostępne są w poniższej tabeli.</li>
        </ol>
        <table>
            <tbody><tr>
                <td colspan="2">
                    <p><strong>OBOWIĄZEK INFORMACYJNY DLA KLIENTA</strong></p>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <p><strong>Informujemy Cię, że przetwarzamy Twoje dane osobowe - szczegółowe informacje znajdziesz poniżej:</strong></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Administrator danych osobowych</strong></p>
                </td>
                <td>
                    <p><strong>Administratorem Twoich danych osobowych jest:</strong></p>
                    <p>PickMode sp. z o. o. z, KRS: 0001041795, NIP 8513294761 (PickMode);</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Dane kontaktowe administratora</strong></p>
                </td>
                <td>
                    <p><strong>Można się z nami skontaktować</strong>:</p>
                    <ol>
                        <li>na adres e-mail:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>;</li>
                        <li>pisemnie, na adres: PickMode sp. z o. o.</li>
                        <li>poprzez formularz elektroniczny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a>.</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Dane kontaktowe inspektora ochrony danych osobowych PickMode</strong></p>
                </td>
                <td>
                    <p>Wyznaczyliśmy osobę odpowiedzialną za ochronę danych osobowych, tj.&nbsp;<strong>inspektora ochrony danych osobowych</strong>, z którym można się skontaktować:</p>
                    <ol>
                        <li>na adres e-mail:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a></li>
                        <li>pisemnie, na adres: PickMode International sp. z o. o.</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Zakres przetwarzanych danych osobowych</strong></p>
                </td>
                <td>
                    <p>Abyś mógł zarejestrować konto w serwisie PickMode oraz mógł w pełni korzystać z funkcji serwisu będziemy przetwarzać następujące <strong>kategorie Twoich danych osobowych</strong>:</p>
                    <ol>
                        <li>Imię i nazwisko,</li>
                        <li>Adres e-mail,</li>
                        <li>Numer telefonu,</li>
                        <li>Adres zamieszkania,</li>
                        <li>IP urządzenia;</li>
                        <li>Data urodzenia,</li>
                        <li>Twoje zdjęcie,</li>
                        <li>Twoją płeć.</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Cele i podstawy prawne przetwarzania danych</strong></p>
                </td>
                <td>
                    <p>Przetwarzamy Twoje dane osobowe&nbsp;w następujących celach:</p>
                    <ol>
                        <li><strong>zawarcia i wykonania umowy&nbsp;o korzystanie z serwisu PickMode</strong>, aby umożliwić Ci korzystanie z usług podmiotów współpracujących z PickMode („<strong>Partnerów</strong>”), w szczególności poprzez udostępnienie Twoich danych osobowych Partnerowi, gdy złożysz żądanie rezerwacji usług Partnera za pomocą aplikacji PickMode – takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji umowy (art. 6 ust. 1 lit. b RODO);</li>
                        <li><strong>marketingu produktów lub usług PickMode (z wyłączeniem marketingu bezpośredniego)</strong>&nbsp;– przetwarzanie Twoich danych osobowych w tym celu odbywać się będzie w realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), którym jest możliwość prowadzenia marketingu własnych produktów i usług;</li>
                        <li><strong>marketingu bezpośredniego produktów lub usług PickMode</strong>&nbsp;– takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), jakim jest możliwość prowadzenia marketingu własnych produktów i usług, polegającego na bezpośredniej komunikacji z Tobą w celu reklamy i promocji usług PickMode;</li>
                        <li><strong>profilowania/grupowania</strong>&nbsp;(tj. prowadzenia analiz, które mogą dotyczyć również danych osobowych, oraz grupowania klientów – w celu dostosowania naszych produktów lub usług oraz ich marketingu do Twoich preferencji, a także do preferencji zidentyfikowanych przez nas określonych grup klientów) – przetwarzanie Twoich danych osobowych w tym celu odbywać się będzie w realizacji naszego prawnie uzasadnionego interesu (art. 6 ust. 1 lit. f RODO);</li>
                        <li><strong>badania preferencji w zakresie zapotrzebowania na usługi PickMode lub Partnerów</strong> (m.in. w postaci ankiet telefonicznych, sms-owych lub e-mailowych)&nbsp;- co jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), którym jest możliwość ustalenia katalogu produktów i usług interesujących klientów oraz poziomu ich zadowolenia z usług PickMode lub usług Partnerów;</li>
                        <li><strong>ustalenia, dochodzenia lub obrony przed roszczeniami, związanymi z zawartą umową lub z przetwarzaniem Twoich danych osobowych</strong> - przetwarzanie Twoich danych osobowych w tym zakresie jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust.1 lit. f RODO), jakim jest możliwość ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami;</li>
                        <li><strong>wypełniania ciążących na PickMode obowiązków prawnych, wynikających z właściwych przepisów prawa</strong> – przetwarzanie Twoich danych osobowych w tym przypadku jest niezbędne do wypełnienia wymogów prawnych, którym podlega PickMode (art. 6 ust. 1 lit. c RODO);</li>
                        <li><strong>tworzenia zestawień, analiz, statystyk na wewnętrzne potrzeby PickMode</strong>&nbsp;(w tym raportowanie, planowanie rozwoju usług, prace rozwojowe w systemie PickMode, tworzenie modeli statystycznych) - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), którym jest analiza i rozwój działalności;</li>
                        <li><strong>wdrażania nowych lub rozwijania rozwiązań/funkcjonalności systemów/aplikacji PickMode</strong>&nbsp;(rozwój funkcjonalności aplikacji, testowanie wprowadzanych nowych rozwiązań, analiza rodzaju koniecznych do wprowadzenia nowych funkcjonalności, tworzenie narzędzi/raportów analitycznych itp.) – takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), którym jest rozwijanie i zapewnianie bezpieczeństwa działania systemów/aplikacji PickMode;</li>
                        <li><strong>w celu odpowiedzi na Twoją wiadomość i komunikacji z Tobą, gdy wyślesz do nas wiadomość</strong> - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), jakim jest możliwość odpowiedzi na Twoją wiadomość i komunikacji z Tobą.</li>
                    </ol>
                    <p>W przypadku, gdy inny Klient PickMode doda Cię w swojej aplikacji PickMode do swojej sieci „Family &amp; Friends” w ramach funkcjonalności aplikacji PickMode „Family &amp; Friends”, przetwarzamy Twoje dane osobowe w następujących celach:</p>
                    <ol>
                        <li><strong>ustalenia, dochodzenia lub obrony przed roszczeniami, związanymi z przetwarzaniem Twoich danych osobowych w związku z umożliwieniem Ci korzystania z usług Partnerów w ramach funkcjonalności aplikacji PickMode „Family &amp; Friends”</strong> – takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), jakim jest prawo do obrony przed ewentualnymi roszczeniami związanymi z korzystaniem przez Ciebie z funkcjonalności aplikacji PickMode „Family &amp; Friends”;</li>
                        <li><strong>udostępnienia Twoich danych osobowych Partnerowi w ramach funkcjonalności aplikacji PickMode „Family &amp; Friends”</strong>- w przypadku, gdy inny Klient PickMode doda Cię w swojej aplikacji PickMode do swojej sieci „Family &amp; Friends” podając wyłącznie Twoje imię i nazwisko, a Ty nie posiadasz konta w aplikacji PickMode, ale chcesz korzystać z funkcjonalności aplikacji PickMode „Family &amp; Friends”, w tym chcesz korzystać z usług Partnerów - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), jakim jest prawo do obrony przed ewentualnymi roszczeniami związanymi z korzystaniem przez Ciebie z funkcjonalności aplikacji PickMode „Family &amp; Friends”;</li>
                        <li><strong>wykonania umowy o korzystanie z serwisu PickMode, tj. funkcjonalności aplikacji PickMode „Family &amp; Friends”, w szczególności poprzez udostępnienie Twoich danych osobowych osobie, która dodała Cię w swojej aplikacji PickMode do swojej sieci „Family &amp; Friends” w ramach funkcjonalności aplikacji PickMode „Family &amp; Friends” </strong>– w przypadku, gdy posiadasz konto w aplikacji PickMode - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji umowy (art. 6 ust. 1 lit. b RODO).</li>
                    </ol>
                    <p>W przypadku, gdy dodasz inną osobę w swojej aplikacji PickMode do swojej sieci „Family &amp; Friends” w ramach funkcjonalności aplikacji PickMode „Family &amp; Friends”, przetwarzamy Twoje dane osobowe w następującym celu:</p>
                    <ol>
                        <li><strong>wykonania umowy o korzystanie z serwisu PickMode, tj. funkcjonalności aplikacji PickMode „Family &amp; Friends”, w szczególności poprzez udostępnienie Twoich danych osobowych osobie, którą dodałeś w swojej aplikacji PickMode do swojej sieci „Family &amp; Friends”</strong> <strong> w ramach funkcjonalności aplikacji PickMode „Family &amp; Friends” i która posiada konto w aplikacji PickMode</strong> - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji umowy (art. 6 ust. 1 lit. b RODO).</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Źródło pochodzenia danych osobowych</strong></p>
                </td>
                <td>
                    <p>W przypadku, gdy zostałeś dodany przez innego Klienta w jego aplikacji PickMode do jego sieci „Family &amp; Friends” w ramach funkcjonalności aplikacji PickMode „Family &amp; Friends”, Twoje dane osobowe zostały przekazane PickMode przez osobę, która dodała Cię w swojej aplikacji do swojej sieci „Family &amp; Friends” w ramach funkcjonalności aplikacji PickMode „Family &amp; Friends”.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Przetwarzanie danych przez Partnerów</strong></p>
                </td>
                <td>
                    <p>Jeśli zdecydujesz się na skorzystanie z usług podmiotów współpracujących z PickMode (Partnerów) i za pomocą serwisu PickMode&nbsp;zarezerwujesz wizytę/usługę u Partnera, PickMode – wykonując zawartą z Tobą umowę o korzystanie z serwisu PickMode – udostępni danemu Partnerowi Twoje dane w zakresie niezbędnym do tego, by mógł on wykonać wybraną przez Ciebie usługę. Partner będzie wtedy przetwarzać Twoje dane jako osobny administrator w celu wykonania zawartej przez niego z Tobą umowy o świadczenie wybranej przez Ciebie usługi i samodzielnie będzie ponosić odpowiedzialność z tym związaną.</p>
                    <p>Będziesz mógł też odrębnie udzielić Partnerowi zgód na przetwarzanie przez niego Twoich danych w innych celach. Partner, realizując własne cele (np. marketing), będzie mógł też korzystać z narzędzi udostępnianych mu przez PickMode. Wtedy PickMode w imieniu takiego Partnera będzie kierować do Ciebie marketing, rekomendacje lub informacje o promocjach.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Profilowanie/grupowanie</strong></p>
                </td>
                <td>
                    <p>Informujemy, że dokonujemy&nbsp;profilowania/grupowania klientów, aby w sposób możliwie odpowiadający ich i Twoim preferencjom świadczyć nasze usługi oraz kierować reklamy, przypomnienia, rekomendacje i promocje. Takie profilowanie/grupowanie może być dokonywane na podstawie następujących danych:</p>
                    <ol>
                        <li>aktywności na stronie oraz w aplikacji PickMode;</li>
                        <li>geolokalizacji;</li>
                        <li>płci;</li>
                        <li>godzin korzystania z usług PickMode;</li>
                        <li>czasu ostatniej aktywności na stronie lub w aplikacji PickMode;</li>
                        <li>analizy zdjęć w serwisie PickMode, które Ci się podobały.</li>
                    </ol>
                    <p>Wyjaśniamy przy tym, że PickMode nie stosuje inwazyjnych praktyk profilowania i śledzenia w celach marketingowych lub reklamowych.</p>
                    <p>Możesz zgłosić sprzeciw wobec takiego przetwarzania Twoich danych osobowych, wysyłając do nas e-mail na adres:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>, dzwoniąc na numer:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;lub kontaktując się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a>.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Kategorie odbiorców danych</strong></p>
                </td>
                <td>
                    <p><strong>Odbiorcami Twoich danych osobowych mogą być:</strong></p>
                    <ol>
                        <li>Partnerzy;</li>
                        <li>spółki z grupy kapitałowej PickMode;</li>
                        <li>podmioty upoważnione na podstawie przepisów prawa (sądy, organy państwowe);</li>
                        <li>podmioty świadczące usługi księgowe, informatyczne, marketingowe, komunikacyjne i analityczne, prawne i windykacyjne;</li>
                        <li>nasi podwykonawcy i inne podmioty, z którymi współpracujemy.</li>
                    </ol>
                    <p>Potwierdzamy, że wszystkie podmioty, na rzecz których przekazujemy Twoje dane osobowe, zapewniają co najmniej taką samą ochronę Twoich danych osobowych, jaka jest określona w niniejszej polityce prywatności i jaka wymagana jest przez wytyczne Apple Inc.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Przekazywanie danych poza Europejski Obszar Gospodarczy</strong></p>
                </td>
                <td>
                    <p>Twoje dane&nbsp;nie będą przekazywane do podmiotów mających siedzibę poza&nbsp;Europejskim Obszarem Gospodarczym.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Okres przechowywania danych</strong></p>
                </td>
                <td>
                    <p>Twoje dane będziemy przetwarzać:</p>
                    <ol>
                        <li><strong>w celu zawarcia i wykonania umowy</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy, a następnie do momentu przedawnienia roszczeń;</li>
                        <li><strong>w celu marketingu produktów lub usług (z wyłączeniem marketingu bezpośredniego)</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu marketingu bezpośredniego produktów lub usług PickMode</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu profilowania/grupowania</strong>&nbsp;– do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>badania preferencji w zakresie zapotrzebowania na usługi</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu ustalenia, dochodzenia lub obrony przed roszczeniami</strong>&nbsp;- do momentu przedawnienia tych roszczeń;</li>
                        <li><strong>wypełniania obowiązków prawnych</strong>&nbsp;- do momentu wygaśnięcia obowiązków przechowywania danych wynikających z przepisów prawa;</li>
                        <li><strong>w celu tworzenia zestawień, analiz, statystyk na wewnętrzne potrzeby</strong>&nbsp;– przez czas trwania umowy, a następnie do czasu przedawnienia roszczeń wynikających z umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu wdrażania nowych lub rozwijania rozwiązań/funkcjonalności systemów/aplikacji PickMode</strong>&nbsp;– do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu odpowiedzi na Twoją wiadomość i komunikacji z Tobą, gdy wyślesz do nas wiadomość</strong> - przez czas komunikacji z Tobą, a następnie do momentu przedawnienia roszczeń.</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Twoje prawa</strong></p>
                </td>
                <td>
                    <p><strong>Zgodnie z RODO, przysługuje Ci:</strong></p>
                    <ol>
                        <li>prawo&nbsp;dostępu&nbsp;do swoich danych oraz otrzymania ich kopii;</li>
                        <li>prawo do&nbsp;sprostowania (poprawiania, uzupełniania)&nbsp;swoich danych;</li>
                        <li>prawo do&nbsp;usunięcia danych, ograniczenia przetwarzania&nbsp;danych;</li>
                        <li>prawo do&nbsp;przenoszenia danych; w celu skorzystania z powyższych praw skontaktuj się z PickMode: poprzez e-mail&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>&nbsp;telefonicznie:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a></li>
                        <li>prawo do wniesienia&nbsp;skargi do organu nadzorczego (Urzędu Ochrony Danych Osobowych).</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Prawo do wycofania zgody</strong></p>
                </td>
                <td>
                    <p>Możesz w dowolnym momencie&nbsp;wycofać zgodę&nbsp;na przetwarzanie danych osobowych – dotyczy to tylko przetwarzania danych, które PickMode będzie przetwarzać na podstawie udzielonej przez Ciebie zgody. W tym celu możesz wysłać do nas e-mail na adres:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>, zadzwonić na numer:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;lub skontaktować się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a>.</p>
                    <p>Wycofanie zgody nie wpłynie na zgodność z prawem przetwarzania danych przed wycofaniem zgody.</p>
                    <p>Jeśli korzystasz z aplikacji PickMode, w każdej chwili możesz sprawdzić, jakich zgód udzieliłeś oraz możesz edytować je wchodząc w swojej aplikacji PickMode w zakładkę „Profil” a następnie w zakładkę „Ustawienia Prywatności”.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Prawo do sprzeciwu</strong></p>
                </td>
                <td>
                    <p>Możesz w każdym czasie&nbsp;wyrazić sprzeciw wobec przetwarzania&nbsp;Twoich danych osobowych przetwarzanych przez PickMode w celu realizacji prawnie uzasadnionego interesu (art. 6 ust. 1 lit. f RODO), wysyłając do nas e-mail na adres:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>, dzwoniąc na numer:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;lub kontaktując się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a>.</p>
                    <p>PickMode nie będzie wtedy wolno już przetwarzać Twoich danych osobowych, chyba że PickMode wykaże istnienie ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Twoich interesów, praw i wolności, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń.</p>
                    <p>W razie zgłoszenia sprzeciwu wobec przetwarzania danych w celu marketingu bezpośredniego – w każdym przypadku PickMode zaprzestanie przetwarzania Twoich danych osobowych w tym celu.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Prawo do usunięcia danych</strong></p>
                </td>
                <td>
                    <p>W każdej chwili możesz żądać usunięcia Twoich danych osobowych z serwisu PickMode. Możesz to zrobić zgłaszając do nas żądanie usunięcia Twoich danych osobowych na adres: <a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>. </p>
                    <p>Jeżeli korzystasz z aplikacji PickMode w systemie iOS możesz usunąć swoje konto wchodząc w swojej aplikacji PickMode w zakładkę „Profil” a następnie w zakładkę „Twoje dane” i klikając w przycisk „Usuń moje konto”. Usunięcie konta w aplikacji PickMode w systemie iOS jest jednoznaczne z usunięciem danych osobowych z aplikacji PickMode.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Informacja o wymogu lub dobrowolności podania danych i konsekwencjach ich niepodania</strong></p>
                </td>
                <td>
                    <p>Podanie Twoich danych osobowych jest&nbsp;dobrowolne, ale niezbędne do zawarcia i wykonywania umowy, na korzystanie z serwisu PickMode oraz realizacji pozostałych ww. celów. </p>
                    <p>W każdej chwili możesz sprawdzić lub edytować swoje dane osobowe, które nam przekazałeś, wchodząc w swojej aplikacji PickMode w zakładkę „Profil”, a następnie w zakładkę „Twoje dane”.</p>
                </td>
            </tr>
        </tbody></table>
        <h2><strong>Załącznik nr 2</strong></h2>
        <h2><strong>Podstawy prawne i cele przetwarzania przez PickMode danych osobowych Partnerów.</strong></h2>
        <ol>
            <li>Administratorem danych osobowych Partnerów, będących osobami fizycznymi, jest PickMode. Obowiązek informacyjny, o którym mowa w art. 13 RODO PickMode wypełnia wobec Partnerów przy Rejestracji Konta. Treść klauzuli informacyjnej dostępna jest w tabeli na końcu załącznika.</li>
            <li>PickMode i Partner przetwarzają dane osobowe Klientów we własnych celach, zatem są odrębnymi administratorami ich danych osobowych.</li>
            <li>Gdy Klient za pomocą Aplikacji dokonuje Rezerwacji usługi Partnera:<ol>
                    <li>dochodzi do zawarcia umowy między Klientem a Partnerem o świadczenie przez Partnera Usług na rzecz Klienta,</li>
                    <li>Klient może w Aplikacji udzielić Partnerowi zgód na przetwarzanie przez niego danych osobowych (np. na komunikację elektroniczną) w innych celach niż w celu wykonania umowy z Klientem,</li>
                    <li>PickMode udostępnia Partnerowi za pomocą Aplikacji dane osobowe Klienta potrzebne do świadczenia przez niego Usług na rzecz tego Klienta.</li>
                    <li>Partner staje się administratorem danych osobowych takiego Klienta, w związku z czym zobowiązany jest do przestrzegania zasad określonych w RODO i ponosi za to odpowiedzialność wynikającą z przepisów.</li>
                </ol>
            </li>
            <li>W celu umożliwienia PickMode świadczenia na rzecz Partnera usług w ramach Aplikacji, do których niezbędne jest przetwarzanie:<ol>
                    <li>danych osobowych Klientów, którzy dokonali Rezerwacji usług Partnera,</li>
                    <li>danych osobowych osób, którymi Partner posługuje się korzystając z Aplikacji (pracowników, zleceniobiorców itp.),</li>
                    <li>konieczne jest powierzenie przez Partnera przetwarzania danych osobowych takich osób, w związku z czym Partner i PickMode zawierają Umowę powierzenia przetwarzania danych osobowych.</li>
                </ol>
            </li>
            <li>Partner jest świadomy, że rozwiązanie Umowy powierzenia przetwarzania danych osobowych skutkować będzie niemożliwością świadczenia przez PickMode usług na rzecz Partnera na podstawie Umowy w zakresie wymagającym przetwarzania danych, których administratorem jest Partner, w związku z czym wraz z rozwiązaniem Umowy powierzenia przetwarzania danych osobowych – zgodnie z art. 475 §1 Kodeksu cywilnego – wygasają zobowiązania PickMode w tym zakresie.</li>
            <li>Gdy Partner będzie korzystać z narzędzi dostępnych w ramach usług PickMode (m.in. kalendarz wizyt, przypomnienia, marketing) – PickMode będzie w imieniu Partnera przetwarzać dane Klienta na zasadach określonych w Umowie powierzenia przetwarzania danych osobowych, a Partner nadal będzie administratorem jego danych osobowych.</li>
            <li>Cele w jakich Partner będzie mógł przetwarzać dane Klienta będą uzależnione od tego, czy i jakich zgód Klient udzielił Partnerowi na przetwarzanie jego danych osobowych, czy tych zgód nie wycofał lub nie zgłosił sprzeciwu wobec przetwarzania przez Partnera jego danych osobowych.</li>
            <li>Partner jest także administratorem danych osobowych Klienta, z którym zawrze umowę poza Aplikacją (np. w salonie). Jeśli wprowadzi te dane do Aplikacji nadal jest ich administratorem, a PickMode przetwarza je w imieniu Partnera na podstawie Umowy powierzenia przetwarzania danych osobowych.</li>
            <li>PickMode przetwarza dane, w tym dane osobowe, Partnera jedynie w zakresie umożliwiającym prawidłowe świadczenie usług i prawidłową realizację Umowy. PickMode nie odpowiada za zakres danych osobowych Klientów zbieranych przez Partnerów i za legalność zbierania tych danych przez Partnerów. Szczegółowe zobowiązania stron z tym związane zawiera Umowa powierzenia przetwarzania danych osobowych.</li>
        </ol>
        <table>
            <tbody><tr>
                <td colspan="2">
                    <p><strong>OBOWIĄZEK INFORMACYJNY DLA PARTNERA</strong></p>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <p><strong>Informujemy Cię, że przetwarzamy Twoje dane osobowe - szczegółowe informacje znajdziesz poniżej:</strong></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Administrator danych osobowych</strong></p>
                </td>
                <td>
                    <p><strong>Administratorem Twoich danych osobowych jest:</strong></p>
                    <p>PickMode International sp. z o. o. KRS: 0001041795, NIP 8513294761 (PickMode);</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Dane kontaktowe administratora</strong></p>
                </td>
                <td>
                    <p><strong>Można się z nami skontaktować:</strong></p>
                    <ol>
                        <li>telefonicznie na nr:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>;</li>
                        <li>na adres e-mail:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>;</li>
                        <li>pisemnie, na adres: PickMode sp. z o. o.</li>
                        <li>poprzez formularz elektroniczny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a>.</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Dane kontaktowe inspektora ochrony danych osobowych PickMode</strong></p>
                </td>
                <td>
                    <p>Wyznaczyliśmy osobę odpowiedzialną za ochronę danych osobowych, tj.&nbsp;inspektora ochrony danych osobowych, z którym można się skontaktować:</p>
                    <ol>
                        <li>na adres e-mail:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a></li>
                        <li>pisemnie, na adres: PickMode International sp. z o. o.</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Zakres przetwarzanych danych osobowych</strong></p>
                </td>
                <td>
                    <p>Abyś mógł zarejestrować konto w serwisie PickMode oraz mógł w pełni korzystać z funkcji serwisu będziemy przetwarzać następujące kategorie Twoich danych osobowych:</p>
                    <ol>
                        <li>Imię i nazwisko,</li>
                        <li>Nazwa Twojego biznesu,</li>
                        <li>Numer NIP,</li>
                        <li>Numer REGON,</li>
                        <li>Adres e-mail,</li>
                        <li>Numer telefonu,</li>
                        <li>Adres zamieszkania/Adres prowadzenia biznesu,</li>
                        <li>IP urządzenia;</li>
                        <li>Data urodzenia</li>
                        <li>Logo,</li>
                        <li>Zdjęcie profilowe.</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Cele i podstawy prawne przetwarzania danych</strong></p>
                </td>
                <td>
                    <p>Przetwarzamy Twoje dane osobowe&nbsp;w następujących celach:</p>
                    <ol>
                        <li><strong>zawarcia i wykonania umowy&nbsp;o korzystanie z serwisu PickMode</strong>– takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji umowy (art. 6 ust. 1 lit. b RODO);</li>
                        <li><strong>marketingu produktów lub usług PickMode (z wyłączeniem marketingu bezpośredniego)</strong>&nbsp;– przetwarzanie Twoich danych osobowych w tym celu odbywać się będzie w realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), którym jest możliwość prowadzenia marketingu własnych produktów i usług;</li>
                        <li><strong>marketingu bezpośredniego produktów lub usług PickMode&nbsp;</strong>– takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), jakim jest możliwość prowadzenia marketingu własnych produktów i usług, polegającego na bezpośredniej komunikacji z Tobą w celu reklamy i promocji usług PickMode;</li>
                        <li><strong>profilowania/grupowania</strong>&nbsp;(tj. prowadzenia analiz, które mogą dotyczyć również danych osobowych, oraz grupowania klientów – w celu dostosowania naszych produktów lub usług oraz ich marketingu do Twoich preferencji, a także do preferencji zidentyfikowanych przez nas określonych grup klientów) – przetwarzanie Twoich danych osobowych w tym celu odbywać się będzie w realizacji naszego prawnie uzasadnionego interesu (art. 6 ust. 1 lit. f RODO);</li>
                        <li><strong>badania preferencji w zakresie zapotrzebowania na usługi PickMode lub Partnerów</strong> (m.in. w postaci ankiet telefonicznych, sms-owych lub e-mailowych)&nbsp;- co jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), którym jest możliwość ustalenia katalogu produktów i usług interesujących klientów oraz poziomu ich zadowolenia z usług PickMode lub usług Partnerów;</li>
                        <li><strong>ustalenia, dochodzenia lub obrony przed roszczeniami, związanymi z zawartą umową lub z przetwarzaniem Twoich danych osobowych</strong> - przetwarzanie Twoich danych osobowych w tym zakresie jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust.1 lit. f RODO), jakim jest możliwość ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami;</li>
                        <li><strong>wypełniania ciążących na PickMode obowiązków prawnych, wynikających z właściwych przepisów prawa</strong> – przetwarzanie Twoich danych osobowych w tym przypadku jest niezbędne do wypełnienia wymogów prawnych, którym podlega PickMode (art. 6 ust. 1 lit. c RODO);</li>
                        <li><strong>tworzenia zestawień, analiz, statystyk na wewnętrzne potrzeby PickMode</strong>&nbsp;(w tym raportowanie, planowanie rozwoju usług, prace rozwojowe w systemie PickMode, tworzenie modeli statystycznych) - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), którym jest analiza i rozwój działalności;</li>
                        <li><strong>wdrażania nowych lub rozwijania rozwiązań/funkcjonalności systemów/aplikacji PickMode</strong>&nbsp;(rozwój funkcjonalności aplikacji, testowanie wprowadzanych nowych rozwiązań, analiza rodzaju koniecznych do wprowadzenia nowych funkcjonalności, tworzenie narzędzi/raportów analitycznych itp.) – takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), którym jest rozwijanie i zapewnianie bezpieczeństwa działania systemów/aplikacji PickMode;</li>
                        <li><strong>w celu odpowiedzi na Twoją wiadomość i komunikacji z Tobą, gdy wyślesz do nas wiadomość</strong> - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu PickMode (art. 6 ust. 1 lit. f RODO), jakim jest możliwość odpowiedzi na Twoją wiadomość i komunikacji z Tobą.</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Profilowanie/grupowanie</strong></p>
                </td>
                <td>
                    <p>Informujemy, że dokonujemy&nbsp;profilowania/grupowania klientów, aby w sposób możliwie odpowiadający ich i Twoim preferencjom świadczyć nasze usługi oraz kierować reklamy, przypomnienia, rekomendacje i promocje. Takie profilowanie/grupowanie może być dokonywane na podstawie następujących danych:</p>
                    <ol>
                        <li>aktywności na stronie oraz w aplikacji PickMode;</li>
                        <li>geolokalizacji;</li>
                        <li>płci;</li>
                        <li>godzin korzystania z usług PickMode;</li>
                        <li>czasu ostatniej aktywności na stronie lub w aplikacji PickMode;</li>
                        <li>analizy zdjęć w serwisie PickMode, które Ci się podobały.</li>
                    </ol>
                    <p>Wyjaśniamy przy tym, że PickMode nie stosuje inwazyjnych praktyk profilowania i śledzenia w celach marketingowych lub reklamowych.</p>
                    <p>Możesz zgłosić sprzeciw wobec takiego przetwarzania Twoich danych osobowych, wysyłając do nas e-mail na adres:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>, dzwoniąc na numer:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;lub kontaktując się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a>.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Kategorie odbiorców danych</strong></p>
                </td>
                <td>
                    <p>Odbiorcami Twoich danych osobowych mogą być:</p>
                    <ol>
                        <li>spółki z grupy kapitałowej PickMode;</li>
                        <li>podmioty upoważnione na podstawie przepisów prawa (sądy, organy państwowe);</li>
                        <li>podmioty świadczące usługi księgowe, informatyczne, marketingowe, komunikacyjne i analityczne, prawne i windykacyjne;</li>
                        <li>nasi podwykonawcy i inne podmioty, z którymi współpracujemy.</li>
                    </ol>
                    <p>Potwierdzamy, że wszystkie podmioty, na rzecz których przekazujemy Twoje dane osobowe, zapewniają co najmniej taką samą ochronę Twoich danych osobowych, jaka jest określona w niniejszej polityce prywatności i jaka wymagana jest przez wytyczne Apple Inc.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Przekazywanie danych poza Europejski Obszar Gospodarczy</strong></p>
                </td>
                <td>
                    <p>Twoje dane&nbsp;nie będą przekazywane do podmiotów mających siedzibę poza&nbsp;Europejskim Obszarem Gospodarczym.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Okres przechowywania danych</strong></p>
                </td>
                <td>
                    <p>Twoje dane będziemy przetwarzać:</p>
                    <ol>
                        <li><strong>w celu zawarcia i wykonania umowy&nbsp;-</strong> do momentu rozwiązania lub wygaśnięcia umowy, a następnie do momentu przedawnienia roszczeń;</li>
                        <li><strong>w celu marketingu produktów lub usług</strong> (z wyłączeniem marketingu bezpośredniego)&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu marketingu bezpośredniego produktów lub usług PickMode&nbsp;-</strong> do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu profilowania/grupowania</strong>&nbsp;– do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>badania preferencji w zakresie zapotrzebowania na usługi</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu ustalenia, dochodzenia lub obrony przed roszczeniami</strong>&nbsp;- do momentu przedawnienia tych roszczeń;</li>
                        <li><strong>wypełniania obowiązków prawnych</strong>&nbsp;- do momentu wygaśnięcia obowiązków przechowywania danych wynikających z przepisów prawa;</li>
                        <li><strong>w celu tworzenia zestawień, analiz, statystyk na wewnętrzne potrzeby</strong>&nbsp;– przez czas trwania umowy, a następnie do czasu przedawnienia roszczeń wynikających z umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu wdrażania nowych lub rozwijania rozwiązań/funkcjonalności systemów/aplikacji PickMode</strong>&nbsp;– do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                        <li><strong>w celu odpowiedzi na Twoją wiadomość i komunikacji z Tobą, gdy wyślesz do nas wiadomość</strong> - przez czas komunikacji z Tobą, a następnie do momentu przedawnienia roszczeń.</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Twoje prawa</strong></p>
                </td>
                <td>
                    <p><strong>Zgodnie z RODO, przysługuje Ci:</strong></p>
                    <ol>
                        <li>prawo&nbsp;dostępu&nbsp;do swoich danych oraz otrzymania ich kopii;</li>
                        <li>prawo do&nbsp;sprostowania (poprawiania, uzupełniania)&nbsp;swoich danych;</li>
                        <li>prawo do&nbsp;usunięcia danych, ograniczenia przetwarzania&nbsp;danych;</li>
                        <li>prawo do&nbsp;przenoszenia danych; w celu skorzystania z powyższych praw skontaktuj się z PickMode: poprzez e-mail&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>&nbsp;telefonicznie:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a></li>
                        <li>prawo do wniesienia&nbsp;skargi do organu nadzorczego (Urzędu Ochrony Danych Osobowych).</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Prawo do wycofania zgody</strong></p>
                </td>
                <td>
                    <p>Możesz w dowolnym momencie&nbsp;wycofać zgodę&nbsp;na przetwarzanie danych osobowych – dotyczy to tylko przetwarzania danych, które PickMode będzie przetwarzać na podstawie udzielonej przez Ciebie zgody. W tym celu możesz wysłać do nas e-mail na adres:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>, zadzwonić na numer:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;lub skontaktować się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a>.</p>
                    <p>Wycofanie zgody nie wpłynie na zgodność z prawem przetwarzania danych przed wycofaniem zgody. </p>
                    <p>W każdej chwili możesz sprawdzić, jakich zgód udzieliłeś oraz możesz edytować je wchodząc w swojej aplikacji PickMode w zakładkę „Profil”, a następnie w zakładkę „Ustawienia”, a następnie w zakładkę „Aplikacja PickMode”, a następnie w zakładkę „Ochrona Twoich danych”.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Prawo do sprzeciwu</strong></p>
                </td>
                <td>
                    <p>Możesz w każdym czasie&nbsp;wyrazić sprzeciw wobec przetwarzania&nbsp;Twoich danych osobowych przetwarzanych przez PickMode w celu realizacji prawnie uzasadnionego interesu (art. 6 ust. 1 lit. f RODO), wysyłając do nas e-mail na adres:&nbsp;<a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>, dzwoniąc na numer:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;lub kontaktując się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://PickMode.com/pl-pl/contact.html">https://PickMode.com/pl-pl/contact.html</a>.</p>
                    <p>PickMode nie będzie wtedy wolno już przetwarzać Twoich danych osobowych, chyba że PickMode wykaże istnienie ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Twoich interesów, praw i wolności, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń.</p>
                    <p>W razie zgłoszenia sprzeciwu wobec przetwarzania danych w celu marketingu bezpośredniego – w każdym przypadku PickMode zaprzestanie przetwarzania Twoich danych osobowych w tym celu.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Prawo do usunięcia danych</strong></p>
                </td>
                <td>
                    <p>W każdej chwili możesz żądać usunięcia Twoich danych osobowych z serwisu PickMode. Możesz to zrobić zgłaszając do nas żądanie usunięcia Twoich danych osobowych na adres: <a href="mailto:rodo@PickMode.com">rodo@PickMode.com</a>.</p>
                    <p>Jeżeli korzystasz z aplikacji PickMode w systemie iOS możesz usunąć swoje konto wchodząc w swojej aplikacji PickMode w zakładkę „Profil” a następnie w zakładkę „Ustawienia”, a następnie w zakładkę „Aplikacja PickMode” a następnie w zakładkę „Usuń konto” i klikając w przycisk „Wyślij prośbę”. Usunięcie konta w aplikacji PickMode w systemie iOS jest jednoznaczne z usunięciem danych osobowych z aplikacji PickMode.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Informacja o wymogu lub dobrowolności podania danych i konsekwencjach ich niepodania</strong></p>
                </td>
                <td>
                    <p>Podanie Twoich danych osobowych jest&nbsp;dobrowolne, ale niezbędne do zawarcia i wykonywania umowy, na korzystanie z serwisu PickMode oraz realizacji pozostałych ww. celów. </p>
                    <p>W każdej chwili możesz sprawdzić lub edytować swoje dane osobowe wchodząc w swojej aplikacji PickMode w zakładkę „Profil”, a następnie w zakładkę „Ustawienia”, a następnie w zakładkę „Dane biznesu”.</p>
                </td>
            </tr>
        </tbody></table>

        <p><small class="lastUpdate">Ostatnia aktualizacja: 12 maja 2022</small></p>

        </div>
</div>
