<div class="parrent">
    <main class="main-container">
        <div class="image">
            <img src="{{ businessData.image }}" alt="">
        </div>
        
        <div>
            <h2 class="business-name">
                {{ businessData.name }}
            </h2>
            
            <div class="stars">
                <div class="item-extended--labels pb-3">
                    <div class="d-flex align-items-center">
                      <div class="label">
                        <img *ngFor="let star of businessData.stars" src="/assets/img/star.svg" alt="star">
                        <span>
                            {{ businessData.opinionsAverage }}
                            {{ businessData.opinions.length }} opini
                        </span>
                      </div>
                    </div>
                  </div>
            </div>
            
            <div class="location">
                {{ businessData.city }}
            </div>
        </div>
    </main>
    
    <div style="padding-top: 20px;"> </div>

    <hr>
</div>

<section class="galery">
    <img *ngFor="let image of businessData.images" (click)="showImage(image)" src="{{ image }}" alt="">
</section>
    
<section class="mt-4 about-us">
    <div class="text">
        <h3 class="mb-4">
            O nas
        </h3>

        {{ businessData.user.description }}
    </div>
</section>

<div style="padding: 30px;"></div>

<div *ngFor="let offer of businessData.offers">
    <section class="service" *ngIf="offer.class === 'left'">
        <div class="content-left">
            <h6 class="mb-3">
                <a routerLink="/Wyswietl-oferte/{{ offer.id }}">
                    {{ offer.title }} {{ offer.estaminated_time }}h
                </a>
            </h6>
            
            <div [innerHTML]="offer.description">
            </div>
        </div>
        
        <div>
            <img src="{{ offer.offer_images[0].image }}" alt="">
        </div>
    </section>

    <section class="service" *ngIf="offer.class === 'right'">
        <div>
            <img src="{{ offer.offer_images[0].image }}" alt="">
        </div>
        
        <div class="content">
            <h6 class="mb-3">
                <a routerLink="/Wyswietl-oferte/{{ offer.id }}">
                    {{ offer.title }} {{ offer.estaminated_time }}h
                </a>
            </h6>
            
            <div [innerHTML]="offer.description">
            </div>
        </div>
    </section>
    
    <section class="service-mobile">
        <div>
            <img src="{{ offer.offer_images[0].image }}" alt="">
        </div>
        
        <div class="content-left">
            <h6 class="mb-3">
                {{ offer.title }} {{ offer.estaminated_time }}h
            </h6>
            
            <div [innerHTML]="offer.description">
            </div>
        </div>
    </section>
</div>

<div style="padding: 30px;"></div>

<section>
    <app-map *ngIf="businessData.longitude" long="{{ businessData.longitude }}" lat="{{ businessData.latitude }}"></app-map>
</section>

<app-footer></app-footer>

<div *ngIf="image" class="image-modal">
    <!-- X BUTTON -->
    <div class="close-button" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
        </svg>
    </div>
    
    <img src="{{ image }}" alt="">
</div>