<div [ngStyle]="{'background-image': 'url(./assets/img/hero-img.jpg'}" class="container-fluid hero">

  <div class="container">
    <div class="row">
      <div class="col text-center text-white">
        <img class="mb-3 d-lg-none" src="/assets/img/pickmode_logo_small.png" alt="pickmode logo">
        <h1>Podkreśl swoje piękno</h1>
        <h2>Odkryj najlepsze salony w okolicy i zarezerwuj wizyte online!</h2>
      </div>
      
      <form>
        <div class="d-flex align-items-center justify-content-center search">
          <label class="label-service search" for="service">
            <img src="/assets/img/magnifier.svg" alt="">
            <form (keydown.enter)="HandleSearch()">
              <input id="service" type="text" placeholder="Znajdź usługę:">
            </form>
          </label>
          <label for="localization">
            <img src="/assets/img/header-input-localizaton-icon.svg" alt="">
            <input name="localization" class="search" id="localization" type="text" placeholder="Lokalizacja">
          </label>
        </div>
      </form>
    </div>
  </div>

  <div class="hero--categories container d-none d-lg-block">
    <ul class="list-unstyled m-0 p-0">
      <li><a routerLink="/Oferty/Kategoria/Fryzjer">Fryzjer</a></li>
      <li><a routerLink="/Oferty/Kategoria/Barber">Barber shop</a></li>
      <li><a routerLink="/Oferty/Kategoria/SalonKosmetyczny">Salon kosmetyczny</a></li>
      <li><a routerLink="/Oferty/Kategoria/Paznokcie">Paznokcie</a></li>
      <li><a routerLink="/Oferty/Kategoria/BrwiIRzesy">Brwi i rzęsy</a></li>
      <li><a routerLink="/Oferty/Kategoria/Masaz">Masaż</a></li>
      <li><a routerLink="/Oferty/Kategoria/Fizjoterapia">Fizjoterapia</a></li>
      <li><a routerLink="/Oferty/Kategoria/Wiecej">Więcej...</a></li>
    </ul>
  </div>


</div>
