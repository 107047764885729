<div>
    <div class="card">
        <h2 class="p-3 text-dark">Edycja profilu</h2>
        <div style="width: 75%; margin-left: auto; margin-right: auto;">
          <div class="input-group mb-3">
            <div class="form-floating" style="width: 100%;">
              <input type="text" class="form-control" id="name" placeholder="name" style="width: 100%;" value="{{ user.name }}">
              <label for="floatingInputGroup1">Nazwa</label>
            </div>
          </div>
        </div>
        
        <div style="width: 75%; margin-left: auto; margin-right: auto;">
          <div class="input-group mb-3">
            <div class="form-floating" style="width: 100%;">
              <input type="email" class="form-control" id="email" placeholder="email" style="width: 100%;" value="{{ user.email }}">
              <label for="floatingInputGroup1">Email</label>
            </div>
          </div>
        </div>

        <div style="width: 75%; margin-left: auto; margin-right: auto;">
            <div class="input-group mb-3">
              <div class="form-floating" style="width: 100%;">
                <input type="text" class="form-control" id="phone_number" placeholder="phone_number" style="width: 100%;"  value="{{ user.phone_number }}">
                <label for="floatingInputGroup1">Nr telefonu</label>
              </div>
            </div>
          </div>

        <div style="width: 75%; margin-left: auto; margin-right: auto;">
          <div class="input-group mb-3">
            <div class="form-floating" style="width: 100%;">
              <input type="text" class="form-control" id="description" placeholder="description" style="width: 100%;" value="{{ user.description }}">
              <label for="floatingInputGroup1">Opis</label>
            </div>
          </div>
        </div>

        <div style="width: 75%; margin-left: auto; margin-right: auto;">
          <div class="input-group mb-3">
            <div class="form-floating" style="width: 100%;">
              <input type="text" class="form-control" id="city" placeholder="city" style="width: 100%;" value="{{ user.city }}">
              <label for="floatingInputGroup1">Miasto</label>
            </div>
          </div>
        </div>

        <div style="width: 75%; margin-left: auto; margin-right: auto;">
          <div class="input-group mb-3">
            <div class="form-floating" style="width: 100%;">
              <input type="text" class="form-control" id="password" placeholder="password" style="width: 100%;" value="{{ user.password }}">
              <label for="floatingInputGroup1">Chcesz zmienić hasło? Wpisz je tutaj!</label>
            </div>
          </div>
        </div>

        
        <button class="btn btn-primary" (click)="Save ()">
          Zapisz zmiany
      </button>

    </div>
</div>