<div class="container-fluid">
  <div class="container">

    <div class="row change-view">
     <div class="col-12 col-lg-9 d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
       <div>Sortuj po:</div>
       <div class="d-flex flex-column flex-lg-row align-items-lg-center change-view--filters">
         <p>Najwyżej ocenianie</p>
         <p>Najczęściej wybierane</p>
         <p>Pokaż najnowsze</p>
       </div>

     </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end"> <div class="btn-default" >
        <img style="margin-right: 7px;" src="/assets/img/show-map-pin.svg" alt="">
        pokaż mapę</div></div>
    </div>

    <!--item == row-->
    <div class="row" *ngFor="let offer of Offers">
      <div class="col item-extended">
        <div class="item-extended--img">
          <img src="/assets/img/item-bg.jpg" alt="item-bg">
        </div>

        <div class="item-extended--content">
          <header>

            <div class="d-flex flex-column">

              <div class="item-extended--entry-info">
                <div class="item-extended--title">{{ offer.title }}</div>
                <div class="item-extended--labels">
                  <div class="d-flex align-items-center">
                    <div class="label">
                      <img src="/assets/img/star.svg" alt="star">
                      <span> 5.0</span>
                    </div>
                    <div class="label">125 Opini</div>
                  </div>
                </div>
              </div>

              <div class="item-extended--address">{{ offer.adres }}</div>
            </div>

          </header>

          <ul>
            <li>
              <div>
                <span class="name">{{ o }}</span>
                <span class="price">{{ offer.price }} pln</span>
              </div>
              <div class="item-extended--btn-wrapper">
                <a class="btn-default" routerLink="/Wyswietl-oferte/{{ offer.id }}">Umów się</a>
              </div>
            </li>
          </ul>

        </div>

      </div>
    </div>



  </div>
</div>
