import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-template-single-order',
  templateUrl: './template-single-order.component.html',
  styleUrls: ['./template-single-order.component.scss']
})
export class TemplateSingleOrderComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
  ) {}

  item: any = {};

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');

      axios.get(environment.API_URL + 'my-orders/' + id, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }})
        .then(res => {
          console.log(res.data);
          this.item = res.data.order;
        }
      );
    });
  }

}
