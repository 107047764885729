import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TemplateHomepageComponent } from './pages/template-homepage/template-homepage.component';
import { TemplateSingleCategoryComponent } from './pages/template-single-category/template-single-category.component';
import { TemplateSingleItemComponent } from './pages/template-single-item/template-single-item.component';
import { TemplateLoginComponent } from './pages/template-login/template-login.component';
import { TemplateRegisterComponent } from './pages/template-register/template-register.component';
import { TemplatePlaceOrderComponent } from './pages/template-place-order/template-place-order.component';
import { TemplateOrdersComponent } from './pages/template-orders/template-orders.component';
import { TemplateSingleOrderComponent } from './pages/template-single-order/template-single-order.component';
import { TemplateGiveFeedbackComponent } from './pages/template-give-feedback/template-give-feedback.component';
import { TemplateRegisterBusinessComponent } from './pages/template-register-business/template-register-business.component';
import { TemplateSingleCityComponent } from './pages/template-single-city/template-single-city.component';
import { TemplateUserDashboardComponent } from './pages/template-user-dashboard/template-user-dashboard.component';
import { TemplateDepositComponent } from './pages/template-deposit/template-deposit.component';
import { TemplatePrivacyPolicyComponent } from './pages/template-privacy-policy/template-privacy-policy.component';
import { TemplateAboutUsComponent } from './pages/template-about-us/template-about-us.component';
import { TemplateTermsOfServiceComponent } from './pages/template-terms-of-service/template-terms-of-service.component';
import { TemplateContactFormComponent } from './pages/template-contact-form/template-contact-form.component';
import { TemplateBusinessCardComponent } from './pages/template-business-card/template-business-card.component';
import { TemplateCustomPageComponent } from './pages/template-custom-page/template-custom-page.component';

const routes: Routes = [
  { path: '', component: TemplateHomepageComponent },
  { path: 'b/:business-name', component: TemplateBusinessCardComponent },
  { path: 'Oferty/Kategoria/:tag', component: TemplateSingleCategoryComponent },
  { path: 'Oferty/Lokalizacja/:location', component: TemplateSingleCityComponent },
  { path: 'Oferty/Szukaj/:query' , component: TemplateSingleCategoryComponent },
  { path: 'Wyswietl-oferte/:id', component: TemplateSingleItemComponent },
  { path: 'Login', component: TemplateLoginComponent },
  { path: 'Register', component: TemplateRegisterComponent },
  { path: 'Zloz-zamowienie/:id', component: TemplatePlaceOrderComponent },
  { path: 'Zamowienia', component: TemplateOrdersComponent },
  { path: 'Zamowienie/:id', component: TemplateSingleOrderComponent },
  { path: 'Daj-opinie/:id', component: TemplateGiveFeedbackComponent },
  { path: 'Rejestruj-Biznes' , component: TemplateRegisterBusinessComponent },
  { path: 'Dashboard', component: TemplateUserDashboardComponent },
  { path: 'Wplac-zadatek/:offer_id', component: TemplateDepositComponent },
  { path: 'Polityka-prywatnosci' , component: TemplatePrivacyPolicyComponent },
  { path: 'O-nas', component: TemplateAboutUsComponent },
  { path: 'Warunki', component: TemplateTermsOfServiceComponent },
  { path: 'Formularz-kontaktowy', component: TemplateContactFormComponent },
  { path: 'Strona/:slug', component: TemplateCustomPageComponent},
  
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
