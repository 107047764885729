<div class="container-fluid">
    <div class="container">
  
      <!--item == row-->
      <div class="row" *ngFor="let offer of Offers">
        <div class="col item-extended">
          <div class="item-extended--img">
            <img src="{{offer.image}}" alt="item-bg" style="width: 100%; height: 100%; max-height: 500px;">
          </div>
  
          <div class="item-extended--content">
            <header>
  
              <div class="d-flex flex-column">
  
                <div class="item-extended--entry-info">
                  <div class="item-extended--title">{{ offer.title }}</div>
                  <div class="item-extended--labels">
                    <div class="d-flex align-items-center">
                    </div>
                  </div>
                </div>
  
                <div class="item-extended--address">{{ offer.adres }}</div>
              </div>
  
            </header>
  
            <ul>
              <li>
                <div>
                  <span class="name">{{ o }}</span>
                  <span class="price">{{ offer.price }} pln</span>
                </div>
                <div class="item-extended--btn-wrapper">
                  <a class="btn-default" routerLink="/Wyswietl-oferte/{{ offer.id }}">Umów się</a>
                </div>
              </li>
            </ul>
  
          </div>
  
        </div>
      </div>

      <h1 *ngIf="Offers.length == 0">
        Brak ofert w tej kategorii
      </h1>
      
      <!---Loading screen-->
      <div class="loading-screen" id="loading" [style.display]="LoadingDisplay" style="text-align: center;">
        <img src="/assets/img/Spinner-0.8s-290px.gif" alt="">
      </div>
  
    </div>
  </div>
  