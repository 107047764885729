import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-template-single-item',
  templateUrl: './template-single-item.component.html',
  styleUrls: ['./template-single-item.component.scss']
})
export class TemplateSingleItemComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
  ) {}

  item: any = {};
  image: any;
  LoadingDisplay = 'block';

  ngOnInit(): void {

    this.item.id = this.route.snapshot.paramMap.get('id')

    axios.get(environment.API_URL + 'Offer' +  '/' + this.route.snapshot.paramMap.get('id'))
      .then(function (response) {
        document.title = response.data.offer.title + ' - Pickmode';

        console.log(response);
        this.item = response.data.offer;
        
        const baseUrl = environment.API_URL.substring(0, environment.API_URL.length - 4);

        this.image = baseUrl + 'OfferImage/' + response.data.offer.image.image
      
        this.LoadingDisplay = 'none';
      }.bind(this))
      .catch(function (error) {
        if (error.response.status === 404) {
          console.log('Not found');
          window.location.href = '/';
        }
      })
  }

}
