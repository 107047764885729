import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
  selector: 'app-template-register',
  templateUrl: './template-register.component.html',
  styleUrls: ['./template-register.component.scss']
})
export class TemplateRegisterComponent implements OnInit {

  constructor(private route:Router) { }

  ngOnInit(): void {
    document.title = 'Rejestracja - Pickmode';
    
    if (localStorage.getItem('accessToken') !== null) {
      window.location.href = '/';
    }
  }

  public HandleRegister () {
    // check if password and confirm password are equal
    const password = (document.getElementById('password') as HTMLInputElement).value;
    const password_repeat = (document.getElementById('password_repeat') as HTMLInputElement).value;

    if (password.length < 6) {
      Swal.fire({
        title: 'Error',
        text: 'Password must be at least 6 characters long',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return;
    }

    if (password !== password_repeat) {
      Swal.fire({
        title: 'Błąd',
        text: 'Hasła nie są identyczne',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    const email = (document.getElementById('email') as HTMLInputElement).value;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      Swal.fire({
        title: 'Błąd',
        text: 'Email nie jest poprawny',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    const data = {
      email: email,
      password: password,
      name: (document.getElementById('name') as HTMLInputElement).value,
      account_type: 'user'
    };

    axios.post(environment.API_URL + 'Registration', data)
      .then(res => {
        Swal.fire({
          title: 'Sukces',
          text: 'Zarejestrowano pomyślnie! Prosimy o potwierdzenie email w celu aktywacji konta.',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
        
        this.route.navigate(['/Login']);
      }).catch(err => {
        Swal.fire({
          title: 'Błąd',
          text: 'Nie udało się zarejestrować użytkownika, dane już istnieją',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      })

  }

}
