<div class="top-bar text-white p-3 container-fluid d-lg-none">

  <div class="container">
    <div class="row">
      <div class="col d-flex align-items-center justify-content-between">
        <p class="p-0 m-0">aplikacja mobilna pickmode</p>
        <a href="#" > <img src="/assets/img/gplay.png" alt="pobierz z google play"></a>
      </div>
    </div>
  </div>

</div>
