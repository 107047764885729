import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import axios from 'axios';

@Component({
  selector: 'app-localization-box',
  templateUrl: './localization-box.component.html',
  styleUrls: ['./localization-box.component.scss']
})
export class LocalizationBoxComponent implements OnInit {

  show_box: boolean = true;

  constructor() { }

  ngOnInit(): void {
    // if localStorage is not set, then show the box
    if (localStorage.getItem('localization_box') !== null) {
      this.show_box = false;
    }
  }

  not_now(): void {
    // set localStorage to not show the box again
    localStorage.setItem('localization_box', 'true');

    // hide the box
    this.show_box = false;
  }

  activate_localization(): void {
    // get location from browser
    navigator.geolocation.getCurrentPosition(function (position) {

      axios.get('https://api.opencagedata.com/geocode/v1/json?key=2b08eb53dc784523b1970c335b6ca6e3&q=' + position.coords.latitude + ',' + position.coords.longitude + '&pretty=1')
        .then(function (response) {
          localStorage.setItem('localization_box', 'true');
          localStorage.setItem('city', response.data.results[0].components.city);

          window.location.reload();
        })


        swal.fire({
          title: 'Lokalizacja została włączona',
          text: 'Od teraz możesz kożystać z usług z lokalizacją',
          icon: 'success',
          confirmButtonText: 'OK'
        });

        console.log(position);
      })
      this.not_now();
  }

}
