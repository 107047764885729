<div class="d-flex justify-content-between" style="background-color: #ffffff;">
    <div class=" h-100 col-xl-7 col-sm-12 col-xs-12">
      <div class="col-sm-12 col-xl-11 form header">
        <h3 class="text-dark fw-500 fs-32">
          Rejestracja
        </h3>
        <p >
          Utwórz swój profil i otwórz się na większą ilość klientów.
        </p>
        <div class="main_data">
          Dane podstawowe
        </div>
  
        <form (keydown.enter)="HandleRegister()">
          <div style="display: flex; align-items: center; margin-top: 10px;">
  
            <div>
              <label for="localization" class="border email_input col-xl-12 f-flex align-items-center" style="margin-top: 8px;">
                <img src="/assets/img/Email.svg" alt="" class="">
                <input name="email" id="email" type="text" placeholder="adres email" class="border-0 h-100" NgModel required>
              </label>
            </div>
  
            <div>
              <label for="localization" class="border email_input col-xl-12" style="margin-top: 8px;margin-left: 8px;">
                <img src="/assets/img/Email.svg" alt="" class="">
                <input name="name" id="name" type="text" placeholder="Imię i nazwisko" class="border-0 h-100" NgModel required>
              </label>
            </div>
  
          </div>
  
  
          <div class="main_data" style="margin-top: 10px;">
            Ustaw hasło
          </div>
  
          <div style="display: flex; align-items: center; margin-top: 10px;">
  
            <div>
              <label for="localization" class="border email_input col-xl-12" style="margin-top: 8px;">
                <img src="/assets/img/Email.svg" alt="" class="">
                <input name="password" id="password" type="password" placeholder="Ustaw hasło" class="border-0 h-100" NgModel required>
              </label>
            </div>
  
            <div>
              <label for="localization" class="border email_input col-xl-12" style="margin-top: 8px;margin-left: 8px;">
                <img src="/assets/img/Email.svg" alt="" class="">
                <input name="password_repeat" id="password_repeat" type="password" placeholder="Powtórz hasło" class="border-0 h-100" NgModel required>
              </label>
            </div>
  
          </div>
        </form>
  
        <div class="mt-4 checkboxes">
          <input type="checkbox" id="privacy_policy"> Akceptacja <a href="#" style="color: #3772FF;"> regulaminu i polityki prywatności </a>
        </div>
  
        <div class="checkboxes">
          <input type="checkbox"> Zgoda na przesyłanie ofert
        </div>
  
        <div class="checkboxes">
          <input type="checkbox"> Potwierdzenie zapoznania się z <a href="#" style="color: #3772FF;">obowiązkiem informacyjnym</a>
        </div>
  
        <div class="text-center m-w-100 mt-3">
          <button class="login_button" (click)="HandleRegister()">
            Zarejestruj się
          </button>
        </div>
  
      </div>
    </div>
  
    <div class="image col-sm-0 col-xl-5">
    </div>
  
  </div>
  