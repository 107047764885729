<div class="container-fluid header">
  <div class="container">
    <div class="row pt-2 pb-2 d-flex align-items-stretch">
      <div class="col d-flex flex-column flex-lg-row align-items-center justify-content-center gap-3">

        <form>
          <div class="d-flex align-items-center justify-content-center">
            <label class="label-service" for="service">
              <img src="/assets/img/magnifier.svg" alt="">
              <form (keydown.enter)="HandleSearch()">
                <input id="service" type="text" placeholder="Znajdź usługę:">
              </form>
            </label>
            <label for="localization">
              <img src="/assets/img/header-input-localizaton-icon.svg" alt="">
              <input name="localization"  id="localization" type="text" placeholder="Lokalizacja">
            </label>
          </div>
        </form>

        <a class="login-register-btns--wrapper" routerLink="/Dashboard">
          <span class="text-dark">
            {{ user.success.name }}
          </span>
        </a>

      </div>
    </div>
    <div class="row pt-4 pb-4 d-none d-lg-block" >
      <div class="col">
        <ul>
          <li><a routerLink="Oferty/Kategoria/Fryzjer" >Fryzjer</a></li>
          <li><a routerLink="Oferty/Kategoria/Barber">Barber shop</a></li>
          <li><a routerLink="Oferty/Kategoria/SalonKosmetyczny">Salon kosmetyczny</a></li>
          <li><a routerLink="Oferty/Kategoria/Paznokcie">Paznokcie</a></li>
          <li><a routerLink="Oferty/Kategoria/BrwiIRzesy">Brwi i rzęsy</a></li>
          <li><a routerLink="Oferty/Kategoria/Masaz">Masaż</a></li>
          <li><a class="d-none d-xl-block" routerLink="/Kategoria/Fizjoterapia">Fizjoterapia</a></li>
          <li><a class="d-none d-xl-block" routerLink="/Kategoria/InstytucjeFinansowe">Instytucje Finansowe</a></li>
          <li><a routerLink="Oferty/Kategoria/Więcej">Więcej...</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
