import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { MarkerService } from './marker.service';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroComponent } from './components/hero/hero.component';
import { FooterComponent } from './components/footer/footer.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { RecommendedItemsComponent } from './components/recommended-items/recommended-items.component';
import { LocalizationBoxComponent } from './components/localization-box/localization-box.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ArticlesBoxComponent } from './components/articles-box/articles-box.component';
import { SeoContentComponent } from './components/seo-content/seo-content.component';
import { ItemComponent } from './components/item/item.component';
import { TemplateSingleCategoryComponent } from './pages/template-single-category/template-single-category.component';
import { TemplateHomepageComponent } from './pages/template-homepage/template-homepage.component';
import { TemplateCategoriesComponent } from './pages/template-categories/template-categories.component';
import { ItemsLoopComponent } from './components/items-loop/items-loop.component';
import { ItemsExtendedViewComponent } from './components/items-extended-view/items-extended-view.component';
import { PopularServicesComponent } from './components/popular-services/popular-services.component';
import { HeaderComponent } from './components/header/header.component';
import { TemplateSingleItemComponent } from './pages/template-single-item/template-single-item.component';
import { SingleItemHeaderComponent } from './components/single-item/single-item-header/single-item-header.component';
import { SingleItemContentComponent } from './components/single-item/single-item-content/single-item-content.component';
import { SingleItemOpinionsComponent } from './components/single-item/single-item-opinions/single-item-opinions.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TemplateRegisterComponent } from './pages/template-register/template-register.component';
import { TemplatePlaceOrderComponent } from './pages/template-place-order/template-place-order.component';
import { TemplateOrdersComponent } from './pages/template-orders/template-orders.component';
import { TemplateSingleOrderComponent } from './pages/template-single-order/template-single-order.component';
import { TemplateGiveFeedbackComponent } from './pages/template-give-feedback/template-give-feedback.component';
import { TemplateRegisterBusinessComponent } from './pages/template-register-business/template-register-business.component';
import { ItemsSearchByTagComponent } from './components/items-search-by-tag/items-search-by-tag.component';
import { TemplateSingleCityComponent } from './pages/template-single-city/template-single-city.component';
import { ItemsSearchByCityComponent } from './components/items-search-by-city/items-search-by-city.component';
import { TemplateUserDashboardComponent } from './pages/template-user-dashboard/template-user-dashboard.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ProfileEditionComponent } from './components/profile-edition/profile-edition.component';
import { MyOpinionsComponent } from './components/my-opinions/my-opinions.component';
import { TemplateDepositComponent } from './pages/template-deposit/template-deposit.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { TemplatePrivacyPolicyComponent } from './pages/template-privacy-policy/template-privacy-policy.component';
import { TemplateAboutUsComponent } from './pages/template-about-us/template-about-us.component';
import { TemplateTermsOfServiceComponent } from './pages/template-terms-of-service/template-terms-of-service.component';
import { TemplateContactFormComponent } from './pages/template-contact-form/template-contact-form.component';
import { TemplateBusinessCardComponent } from './pages/template-business-card/template-business-card.component';
import { MapComponent } from './map/map.component';
import { TemplateCustomPageComponent } from './pages/template-custom-page/template-custom-page.component';


@NgModule({
  declarations: [
    AppComponent,
    HeroComponent,
    FooterComponent,
    TopBarComponent,
    RecommendedItemsComponent,
    LocalizationBoxComponent,
    CategoriesComponent,
    ArticlesBoxComponent,
    SeoContentComponent,
    ItemComponent,
    TemplateSingleCategoryComponent,
    TemplateHomepageComponent,
    TemplateCategoriesComponent,
    ItemsLoopComponent,
    ItemsExtendedViewComponent,
    PopularServicesComponent,
    HeaderComponent,
    TemplateSingleItemComponent,
    SingleItemHeaderComponent,
    SingleItemContentComponent,
    SingleItemOpinionsComponent,
    TemplateRegisterComponent,
    TemplatePlaceOrderComponent,
    TemplateOrdersComponent,
    TemplateSingleOrderComponent,
    TemplateGiveFeedbackComponent,
    TemplateRegisterBusinessComponent,
    ItemsSearchByTagComponent,
    TemplateSingleCityComponent,
    ItemsSearchByCityComponent,
    TemplateUserDashboardComponent,
    OrdersComponent,
    ProfileEditionComponent,
    MyOpinionsComponent,
    TemplateDepositComponent,
    CheckoutComponent,
    TemplatePrivacyPolicyComponent,
    TemplateAboutUsComponent,
    TemplateTermsOfServiceComponent,
    TemplateContactFormComponent,
    TemplateBusinessCardComponent,
    MapComponent,
    TemplateCustomPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    })
  ],
  providers: [
    MarkerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
