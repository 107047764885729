import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  capitals: string = '/assets/data/usa-capitals.geojson';

  constructor(private http: HttpClient) {
  }

  makeCapitalMarkers(map: L.map, mapData): void {
    const lon = mapData.long;
    const lat = mapData.lat;
    const marker = L.marker([lat, lon]);
    
    marker.addTo(map);
  }
}