import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-template-business-card',
  templateUrl: './template-business-card.component.html',
  styleUrls: ['./template-business-card.component.scss']
})
export class TemplateBusinessCardComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
  ) {}

  image: string;
  businessData: any;

  ngOnInit(): void {
    axios.get(environment.API_URL + 'Business-card/' + this.route.snapshot.paramMap.get('business-name'))
      .then(function (response) {
        this.businessData = response.data;
        let count = 0;
        this.businessData.offers.forEach(offer => {
          count++;
          count % 2 === 0 ? offer.class = 'right' : offer.class = 'left';
        });

        this.businessData.opinionsAverage = 0;
        this.businessData.opinions.forEach(opinion => {
          this.businessData.opinionsAverage += opinion.rating;
        });
        this.businessData.opinionsAverage = this.businessData.opinionsAverage / this.businessData.opinions.length;
        this.businessData.opinionsAverage = this.businessData.opinionsAverage.toFixed(1);
        this.businessData.stars = [];
        for (let i = 0; i < this.businessData.opinionsAverage; i++) {
          this.businessData.stars.push(i);
        }
      }.bind(this))
      .catch(function (error) {
        if (error.response.status === 404) {
          window.location.href = '';
        }
      });
  }

  showImage (imageUrl: string): void {
    document.body.style.overflow = 'hidden';
    this.image = imageUrl;
  }

  closeModal (): void {
    document.querySelector('.image-modal').classList.add('slide-out');
    setTimeout(() => {
      document.body.style.overflow = 'auto';
      this.image = null;
    }, 500);
  }
}
