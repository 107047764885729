<!-- Template for
      displaying homepage -->

<app-top-bar></app-top-bar>
<app-hero></app-hero>

<app-recommended-items></app-recommended-items>
<app-localization-box></app-localization-box>
<!--<app-categories></app-categories>
<app-seo-content></app-seo-content>-->
<app-recommended-items></app-recommended-items>

<app-footer></app-footer>
