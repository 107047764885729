import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private route:Router) { }

  user: any;

  ngOnInit(): void {
    axios.get(environment.API_URL + 'user', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      }
    })
      .then(response => {
        this.user = response.data;
      })
      .catch(error => {
        if (error.response.status === 401) {
          const avaiableSitesWithoutLogin = [
            'Warunki',
            'Formularz-kontaktowy',
            'Polityka-prywatnosci',
            'O-nas',
          ];

          if (avaiableSitesWithoutLogin.includes(window.location.pathname.split('/')[1])) {
            return;
          }

          localStorage.removeItem('accessToken');
          window.location.href = '/Login';
        }
      });
  }

  // tslint:disable-next-line:typedef
  HandleSearch() {
    const search = (document.getElementById('service') as HTMLInputElement).value;

    this.route.navigate(['/Oferty/Kategoria/' + search]);
  }

}
