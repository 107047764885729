import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-template-give-feedback',
  templateUrl: './template-give-feedback.component.html',
  styleUrls: ['./template-give-feedback.component.scss']
})
export class TemplateGiveFeedbackComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    document.title = 'Daj opinię - Pickmode';
  }

  GiveFeedback() {
    const header = (document.getElementById('title')  as HTMLInputElement).value;
    const content = (document.getElementById('content') as HTMLInputElement).value;
    const rating = (document.getElementById('rating') as HTMLInputElement).value;

    if (header === '') {
      swal.fire({
        title: 'Błąd',
        text: 'Musisz napisać tytuł',
        icon: 'error',
        confirmButtonText: 'OK'
      });

      return;
    }
    else if (content === '') {
      swal.fire({
        title: 'Błąd',
        text: 'Musisz napisać treść',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      
      return;
    }
    
    const config = {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('accessToken')
      }
    };

    const postdata = {
      title: header,
      body: content,
      rating: rating
    };
    this.route.paramMap.subscribe((params: ParamMap) => {
      axios.post(environment.API_URL + 'Offer/'+ params.get('id') +'/Comment', postdata, config)
      .then(response => {
        swal.fire({
          title: 'Sukces',
          text: 'Komentarz został dodany',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      });
    });

    
  }

}
