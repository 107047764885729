import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Router} from '@angular/router';

import axios from 'axios';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-template-login',
  templateUrl: './template-login.component.html',
  styleUrls: ['./template-login.component.scss']
})
export class TemplateLoginComponent implements OnInit {

  constructor(private route:Router) {


  }

  ngOnInit(): void {
    document.title = 'Zaloguj się - Pickmode';

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      window.location.href = '/';
    }

  }

  HandleLogin () {
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement).value;

    axios.post(environment.API_URL + 'Login', {
      email: email,
      password: password
    }).then(res => {

      Swal.fire({
        title: 'Success',
        text: 'Poprawnie zalogowano',
        icon: 'success'
      });

      localStorage.setItem('accessToken', res.data.token.plainTextToken);

      this.route.navigate(['/']);
    }
    ).catch(err => {
      if (err.response.status === 401 && err.response.data.error === 'Please verify your email first') {
        Swal.fire({
          title: 'Błąd',
          text: 'Email nie został zweryfikowany, proszę sprawdź swoją pocztę wraz z folderem spam',
          icon: 'info'
        });
        return ;
      }
      Swal.fire({
        title: 'Błąd',
        text: 'Dane nie wystepuja w bazie',
        icon: 'error'
      });
    })
  }

}
