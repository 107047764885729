import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-single-category',
  templateUrl: './template-single-category.component.html',
  styleUrls: ['./template-single-category.component.scss']
})
export class TemplateSingleCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.title = 'Wyszukiwanie - Pickmode';
  }

}
