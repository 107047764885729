<div class="container-fluid single-item-header`">
  <div class="container">
    <div class="row  flex-lg-nowrap">
      <div class="col-12 col-lg-6 col-xl-8 single-item-header-col-1" style="background-image: url({{image}});">
      </div>
      <div class="col-12 col-lg-6 col-xl-4 single-item-header-col-2">
        <header>

          <div class="single-item-header--title pb-2">
            {{ title }}
          </div>

          <div class="item-extended--labels pb-3">
            <div class="d-flex align-items-center">
              <div class="label">
                <img src="/assets/img/star.svg" alt="star">
                <span>{{ rating }}</span>
              </div>
              <div class="label">{{ comments.length }} Opini</div>
            </div>
          </div>

          <div class="single-item-header--address pt-2 mt-2 pb-2">
            <ul class="pt-2">
              <li>
                <img src="/assets/img/header-input-localizaton-icon.svg" alt="">
                {{ adres }}
              </li>
              <li>
                <img src="/assets/img/phone-icon.svg" alt="">
                (+48) {{ phone }}
              </li>
            </ul>
          </div>

          <div class="single-item-header--socials">
            <ul class="pt-3">
              <li>
                <a href="{{ facebook }}">
                  <img src="/assets/img/single-item/facebook.png" alt="">
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="/assets/img/single-item/twitter.png" alt="">
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="/assets/img/single-item/linkedin.png" alt="">
                </a>
              </li>
            </ul>
          </div>

        </header>
      </div>
    </div>
  </div>
</div>
