import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-template-custom-page',
  templateUrl: './template-custom-page.component.html',
  styleUrls: ['./template-custom-page.component.scss']
})
export class TemplateCustomPageComponent implements OnInit {

  pageTitle: any;
  pageContent: any;

  constructor() {
  }

  ngOnInit(): void {
    axios.get(environment.API_URL + 'custom-page/' + window.location.pathname.split('/')[2]).then((response) => {
      this.pageContent = response.data.data.content;
      this.pageTitle = response.data.data.title;
    }).catch(() => {
      window.location.href = '/';
    });
  }

}
