import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-template-place-order',
  templateUrl: './template-place-order.component.html',
  styleUrls: ['./template-place-order.component.scss']
})
export class TemplatePlaceOrderComponent implements OnInit {

  constructor (
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  item: any = {};
  image: string;

  ngOnInit(): void {
    document.title = "Złóż zamówienie - Pickmode";

    axios.get(environment.API_URL + 'Offer' +  '/' + this.route.snapshot.paramMap.get('id'))
      .then(function (response) {
        console.log(response);
        this.item = response.data.offer;

        if (this.item.employees.length <= 0) {
          Swal.fire({
            title: 'Błąd',
            text: 'Oferta nie jest poprawnie skonfigurowana',
            icon: 'error',
            confirmButtonText: 'OK'
          });

          this.location.back();
        }
        
        this.item.employees.forEach(employee => {
          const url = environment.API_URL.substring(0, environment.API_URL.length - 4);
          employee.image = url + 'uploads/profile_images/' + employee.image
        });
        
        const baseUrl = environment.API_URL.substring(0, environment.API_URL.length - 4);

        this.image = baseUrl + 'OfferImage/' + response.data.offer.image.image
      }.bind(this))
      .catch(function (error) {
        if (error.response.status === 404) {
          console.log('Not found');
          window.location.href = '/';
        }
      })

      const config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }

      const offer_id = this.route.snapshot.paramMap.get('id');

      axios.get(environment.API_URL + 'Offer/' + offer_id + '/CanIPlaceOrder', config)
      .then(function (response) {
        //
      }.bind(this))
      .catch(function (error) {
        Swal.fire({
          title: 'Błąd',
          text: 'Nie możesz złożyć zamówienia, ponieważ właściciel oferty nie akceptuje nowych klientów',
          icon: 'error',
          confirmButtonText: 'Ok'
        }).then(() => {
          this.location.back();
        });
      }.bind(this));
  }

  ChangeEmployee(employee) {
    
    this.item.employees.forEach(element => {
      element.checked = false;
      element.border = 'none';
    });

    employee.checked = !employee.checked;
    employee.border = employee.checked ? '1.5px solid #000000' : '1px solid #00000';

    if (!employee.checked) {
      employee.border = ''
    }
  
  }

  public PlaceOrder () {
    
    // validate if user can place order
    const time = (document.getElementById('time') as HTMLInputElement).value;
    const date = (document.getElementById('date') as HTMLInputElement).value;

    const employees = this.item.employees.filter(employee => employee.checked);
    if (employees.length === 0) {
      Swal.fire({
        title: 'Błąd',
        text: 'Nie wybrano pracownika',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    if (!date) {
      Swal.fire({
        title: 'Błąd',
        text: 'Nie wybrano daty',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    if (!time) {
      Swal.fire({
        title: 'Błąd',
        text: 'Nie wybrano godziny',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }


    const day = new Date(date).getDay()-1;

    // search hours in array where day is equal to selected day
    const hours = this.item.hours.filter(hour => hour.day_of_week === day)[0];

    console.log(hours);

    
    if (time < hours.start_time || time > hours.end_time) {
      Swal.fire({
        title: 'Błąd',
        text: 'Wybrana godzina nie jest w zakresie pracy lokalu',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    // if date is in past
    const dateNow = new Date();
    const dateSelected = new Date(date);
    if (dateSelected < dateNow) {
      Swal.fire({
        title: 'Błąd',
        text: 'Wybrana data jest w przeszłości',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    };

    const offer_id = this.route.snapshot.paramMap.get('id');

    axios.post(environment.API_URL + 'place-order/' + offer_id, {
      
      date: date,
      start_time: time,
      offer_id: this.route.snapshot.paramMap.get('id'),
      employee_id: employees[0].id

    },config).then(function (response) {
      
      console.log(response);
      
      Swal.fire({
        title: 'Zamówienie złożone',
        text: 'Zamówienie zostało złożone',
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then(() => {
        window.location.href = '/Zamowienia';
      });

    })
    .catch(function (error) {
      if (error.response.status === 401 && error.response.data.error === 'Business is not accepting new customers') {
    
        Swal.fire({
          title: 'Błąd',
          text: 'Lokal nie akceptuje nowych klientów',
          icon: 'error',
          confirmButtonText: 'Ok'
        }).then(() => {
          window.location.href = '/';
        });
    
      }

      if (error.response.status === 401 && error.response.data.error === 'Conflicting order') {
        
        Swal.fire({
          title: 'Błąd',
          text: 'Przepraszamy, ale wybrany pracownik ma już zamówienie w tym terminie. Oferujemy zapisiane się do listy rezerwowych.',
          icon: 'info',
          showDenyButton: true,
          confirmButtonText: 'Tak, zapisz mnie',
          denyButtonText: `nie, dziękuję`,
        
        }).then((result) => {
        
          if (result.isConfirmed) {
            const postData = {
              date: date,
              start_time: time,
              employee_id: employees[0].id,
              offer_id: offer_id
            }

            axios.post(environment.API_URL + 'Reserve-list', postData, config)
              .then(function (response) {
                console.log(response);
                Swal.fire({
                  title: 'Zapisano',
                  text: 'Zostałeś zapisany do listy rezerwowych',
                  icon: 'success',
                  confirmButtonText: 'Ok'
                }).then(() => {
                  window.location.href = '/Zamowienia';
                });
              })
              .catch(function (error) {
                console.log(error);
              }.bind(this));

          } else if (result.isDenied) {
            Swal.fire('Akcja się nie udała, przeprasamy!', '', 'info')
          }
        });
      }
    });
  }

}
