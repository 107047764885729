<div class="container-fluid recommended-items">
  <div class="container">

    <div class="row">
     <div class="col d-flex  align-items-center">
       <h3 class="pe-5 m-0">Polecane w</h3>
       <ul class="list-unstyled d-flex align-items-center justify-content-center gap-4 p-0 m-0">
         <li><a routerLink="Oferty/Lokalizacja/Poznan" >Poznaniu</a></li>
         <li><a routerLink="Oferty/Lokalizacja/Wroclaw" >Wrocławiu</a></li>
         <li><a routerLink="Oferty/Lokalizacja/Gdansk">Gdańsku</a></li>
         <li><a routerLink="Oferty/Lokalizacja/Katowice" >Katowicach</a></li>
         <li><a routerLink="Oferty/Lokalizacja/Szczecin" >Szczecinie</a></li>
         <li><a routerLink="Oferty/Lokalizacja/Warszawa" >Warszawie</a></li>
       </ul>
     </div>
    </div>
    
    <!-----foreach recommendedItems -->
    <div class="row pt-5 mt-3">

      <a class="col-12 col-lg-4" *ngFor="let item of recommendedItems" routerLink="Wyswietl-oferte/{{ item.id }}">

        <app-item title="{{ item.title }}" adress="{{item.adres}}" image="{{ item.image }}"></app-item>
      </a>

      <div *ngIf="!recommendedItems[0]">
        <h3 class="text-center mb-5 mt-5 font-weight-bold ">Brak ofert w twoim mieście: {{location}}</h3>
      </div>

  </div>

</div>
