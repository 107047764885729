import { Component, Input, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-single-item-content',
  templateUrl: './single-item-content.component.html',
  styleUrls: ['./single-item-content.component.scss']
})
export class SingleItemContentComponent implements OnInit {

  @Input() description: any;
  @Input() id: any;
  @Input() phone: any;
  @Input() adres: any;

  employees: any;
  offer: any;
  image: string;
  links: [];

  constructor() {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {

    const config = {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('accessToken')
      }
    };

    axios.get(environment.API_URL + 'Offer/' + this.id, config)
      .then(function (response) {
        this.employees = response.data.offer.employees;

        this.employees.forEach(employee => {
          const url = environment.API_URL.substring(0, environment.API_URL.length - 4);
          employee.image = url + 'uploads/profile_images/' + employee.image
        });

        this.offer = response.data.offer;
        this.image = environment.API_URL + 'OfferImage/' + response.data.offer.image.image

        
        
      }.bind(this))

      .catch(function (error) {
        console.log(error);
      }.bind(this));


  }


}
