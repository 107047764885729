<app-header></app-header>
<div class="empty-space"></div>
<div class="container ">
    <div class="row">
        <div class="col-lg-12">
            <h1 class="text-center text-dark">Skontakuj się z nami</h1>
        </div>
    </div>
    
    <div class="d-flex" style="justify-content: space-between;">
        <div>
            <input type="text" class="input-main" placeholder="Imię" id="name">
        </div>
        <div>
            <input type="text" class="input-main" placeholder="Nazwisko" id="surname">
        </div>
    </div>

    <div>
        <input type="text" class="input-w" placeholder="email" id="email">
    </div>

    <div>
        <textarea placeholder="Wiadomość" name="" id="details" cols="30" rows="10" class="textarea-details"></textarea>
    </div>

    <div>
        <input type="text" class="input-w" placeholder="dodatkowe informacje" id="info">
    </div>

    <div class="d-flex justify-content-center">
        <button class="btn btn-primary w-100" (click)="SendForm()" >Wyślij</button>
    </div>
</div>