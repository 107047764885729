<app-header></app-header>
<div style="padding-top:180px;"></div>
<div class="card col-xl-10 mt-5 mb-5 p-3" id="configuration" style="margin-left: auto; margin-right: auto">
    <div class="card-body text-center">
      <h1 class="text-dark">Konfiguracja zamówienia</h1>
  
      <label for="date" class="text-dark mt-3"> data </label>
      <input type="date" id="date" class="w-100 date_input">
  
      <label for="date" class="text-dark mt-3"> Godzina </label>
      <input type="time" id="time" class="w-100 date_input">
  
      <div class="row mt-3">
        <div class="col">
  
          <h3>Wybierz pracownika</h3>
          {{offer.employees[0].name}}
          <div style="float: left; width: auto;border-radius: 5px; padding: 5px; margin: 5px;" class="" *ngFor="let employee of offer.employees" (click)="ChangeEmployee(employee)" [style.border]="employee.border" role="button">
            <img src="{{ employee.image }}" alt="pracownik: {{employee.user.name}}"  style="width: 100px; height: 100px; border-radius: 100000px;"> <br>
            {{ employee.user.name }}
          </div>
  
        </div>
      </div>
  
      <button class="btn btn-primary" (click)="PlaceOrder()" > Złóż zamówienie </button>
  
    </div>
</div>
  

<div class="card col-xl-10 mt-5 mb-5 p-3" id="checkout" style="margin-left: auto; margin-right: auto; display: none;" >
    <h1 class="text-dark text-center">
        Zapłać wymagany zadatek: {{ offer.price * offer.deposit }}zł
    </h1>
    <div #paypal class="text-center payment-module"></div>
</div>