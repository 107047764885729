import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-single-item-opinions',
  templateUrl: './single-item-opinions.component.html',
  styleUrls: ['./single-item-opinions.component.scss']
})
export class SingleItemOpinionsComponent implements OnInit {

  constructor() { }

  @Input() comments: any;

  ngOnInit(): void {
    
    setTimeout(() => {
      this.getStars();
    }
    , 1000);
  
  }

  getStars() {
  
    this.comments.forEach(comment => {

      comment.stars = [];

      for (let i = 0; i < comment.rating; i++) {
        comment.stars.push(true);
      }
    });

  }

}
