import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-items-search-by-tag',
  templateUrl: './items-search-by-tag.component.html',
  styleUrls: ['./items-search-by-tag.component.scss']
})
export class ItemsSearchByTagComponent implements OnInit {

  LoadingDisplay = 'block';
  Offers: any;

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // get route params
    this.route.paramMap.subscribe((params: ParamMap) => {
      // get city from route params
      const tag = params.get('tag');
      
      axios.get(environment.API_URL + 'Offer/Tag/' + tag)
        .then(function (response) {
          this.Offers = response.data;

          const baseUrl = environment.API_URL.substring(0, environment.API_URL.length - 4);

          this.Offers.forEach(element => {
            
            if (element.image != null) {
              element.image = baseUrl + 'OfferImage/' + element.image.image;
            }

          });

          console.log(this.Offers);

          this.LoadingDisplay = 'none';

        }.bind(this))
      })
  }

  ngAfterViewInit() {
  }
}
