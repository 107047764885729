<div class="container localization-box--wrapper" *ngIf="show_box">
  <div class="row">

    <div class="col">
    </div>

    <div class="col-10  col-lg-8 bg-main localization-box--inner">
      <div class="localization-box--icon  w-full">
        <img src="/assets/img/localizaton-icon.png" alt="localization-icon">
      </div>
      <div class="h3">Włącz usługi lokalizacji</div>
      <p class="text-center mt-3">Dzięki temu będziemy mogli pokazać Tobie usługi , w Twoim pobliżu</p>
      <div class="d-flex flex-column flex-lg-row gap-3 mt-3">
        <a class="btn btn-border" (click)="activate_localization()" >Ok chętnie !</a>
        <a class="btn btn-txt" (click)="not_now ()">Nie teraz</a>
      </div>
    </div>

    <div class="col">
    </div>

  </div>
</div>
