<div class="footer">

  <div class="container">

    <div class="row footer-row-1 pb-3 pt-4">
      <div class="col d-flex justify-content-center justify-content-lg-start gap-3">
        <a href="#"><img src="/assets/img/gplay.png" alt="pobierz z google play"></a>
        <a href="#">  <img src="/assets/img/appstore.png" alt="pobierz z app store"></a>
      </div>
    </div>

    <div class="row pt-3 mt-3 footer-row-2">
      <div class="col">
        <ul class="list-unstyled d-flex flex-column text-center pb-4 flex-lg-row text-lg-start gap-lg-3 p-lg-0  m-0">
          <li>
            <a routerLink="/O-nas">o nas</a>
          </li>
          <li>
            <a routerLink="/Polityka-prywatnosci">polityka prywatności</a>
          </li>
          <li>
            <a routerLink="/Warunki">Warunki korzystania z serwisu</a>
          </li>
          <li>
            <a routerLink="/Formularz-kontaktowy">Kontakt</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 d-flex justify-content-center justify-content-lg-end gap-2">
        <a href="#"><img src="/assets/img/facebook.png" alt="facebook"></a>
        <a href="#"><img src="/assets/img/twitter.png" alt="twitter"></a>
        <a href="#"><img src="/assets/img/linkedin.png" alt="linkedin"></a>
        <a href="#"><img src="/assets/img/youtube.png" alt="youtube"></a>
        <a href="#"><img src="/assets/img/instagram.png" alt="instagram"></a>
      </div>
    </div>

  </div>

</div>

<div class="footer-bottom">
  <div class="container pt-3">
    <div class="row">
      <div class="col pb-3 text-center text-lg-start d-flex " style="justify-content:space-between;">
        <small>2022 Pickmode .Wszystkie prawa zastrzeżone  </small> <small> <a href="https://antoniseba.pl"> realizacja Antoni Seba </a> </small>
      </div>
    </div>
  </div>

</div>
