<div class="container-fluid single-item-opinions">
  <div class="container">

    <!--opinie-->
    <div class="row mt-3">
      <div class="col">
        <h3>
          Opinie
        </h3>

        <div class="opinions">

          <div class="opinion" *ngFor="let comment of comments">

            <header>
              <div>
                <img src="/assets/img/single-item/opinion-avatar.png" alt="">
              </div>
              <div class="d-flex flex-column">

                <div class="d-flex">
                  <div class="opinion--name">{{ comment.user.name }}</div>
                  <div class="opinion--rating">
                    <span *ngFor="let item of comment.stars">
                      <img src="/assets/img/star.svg" alt="">
                    </span>
                  </div>
                </div>

                <div class="d-flex mt-2">
                  <div class="opinion--date">dodano: {{ comment.created_at }}</div>
                </div>

              </div>
            </header>
            <main>
              {{ comment.body }}

              <div class="opinion--actions">
                <div class="label">
                  <img src="/assets/img/like.svg" alt="">
                </div>
                <div class="label">
                  <img src="/assets/img/unlike.svg" alt="">
                </div>
              </div>
            </main>

          </div>

          <div class="h1" *ngIf="comments.length == 0">
            Brak opini
          </div>

        </div>

      </div>
    </div>

  </div>
</div>
