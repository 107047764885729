<app-header></app-header>
<div class="empty-space" style="padding-top: 200px;"></div>
<app-single-item-header title="{{ item.title }}" phone="{{ item.telephone }}" adres="{{ item.adres }}" image="{{image}}"></app-single-item-header>

<div class="card col-xl-10 mt-5 mb-5" style="margin-left: auto; margin-right: auto">
  <div class="card-body text-center">
    <h1 class="text-dark">Konfiguracja zamówienia</h1>

    <label for="date" class="text-dark mt-3"> data </label>
    <input type="date" id="date" class="w-100 date_input">

    <label for="date" class="text-dark mt-3"> Godzina </label>
    <input type="time" id="time" class="w-100 date_input">

    <div class="row mt-3">
      <div class="col">

        <h3>Wybierz pracownika</h3>
        {{item.employees[0].name}}
        <div style="float: left; width: auto;border-radius: 5px; padding: 5px; margin: 5px;" class="" *ngFor="let employee of item.employees" (click)="ChangeEmployee(employee)" [style.border]="employee.border" role="button">
          <img src="{{ employee.image }}" alt="pracownik: {{employee.user.name}}"  style="width: 100px; height: 100px; border-radius: 100000px;"> <br>
          {{ employee.user.name }}
        </div>

      </div>
    </div>

    <button class="btn btn-primary" (click)="PlaceOrder()" > Złóż zamówienie </button>

  </div>
</div>
