import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-template-user-dashboard',
  templateUrl: './template-user-dashboard.component.html',
  styleUrls: ['./template-user-dashboard.component.scss']
})
export class TemplateUserDashboardComponent implements OnInit {
  LoadingDisplay = 'block';

  constructor() { }

  ngOnInit(): void {
    document.title = 'Profil użytkownika - Pickmode';
    // wait 1,2 seconds before showing loading
    setTimeout(() => {
      this.LoadingDisplay = 'none';
    }, 1200);
    
  }

  Logout() {
    axios.get(environment.API_URL + 'Logout')
      .then(response => {
        console.log(response);
        localStorage.removeItem('accessToken');

        swal.fire({
          title: 'Wylogowano',
          text: 'Zostałeś wylogowany',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          window.location.href = '/Login';
        });

      })
      .catch(error => {
        console.log(error);

        localStorage.removeItem('accessToken');

        swal.fire({
          title: 'Wylogowano',
          text: 'Zostałeś wylogowany',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          window.location.href = '/Login';
        });

      });

  }

}
