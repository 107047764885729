<div class="container-fluid categories">
  <div class="container">

    <div class="row">
      <div class="col text-center">
        <h3 class="text-dark">Znajdź inspirację z pickmode</h3>
        <p>Przeczytaj artykuły z poradami specjalistów</p>
      </div>
    </div>

    <div class="row mt-5 pt-5">
      <div class="d-flex flex-wrap flex-lg-nowrap justify-content-center gap-3">

        <div class="category-item">
          <img src="/assets/img/cat-1.jpg" alt="brwi i rzęsy">
          <h5 class="text-dark text-center">Brwi i rzęsy</h5>
        </div>

        <div class="category-item">
          <img src="/assets/img/cat-2.jpg" alt="Fryzjer">
          <h5 class="text-dark text-center">Fryzjer</h5>
        </div>

        <div class="category-item">
          <img src="/assets/img/cat-3.jpg" alt="Barber">
          <h5 class="text-dark text-center">Barber</h5>
        </div>

        <div class="category-item">
          <img src="/assets/img/cat-4.jpg" alt="Paznokcie">
          <h5 class="text-dark text-center">Paznokcie</h5>
        </div>

        <div class="category-item">
          <img src="/assets/img/cat-5.jpg" alt="Kosmetyka">
          <h5 class="text-dark text-center">Kosmetyka</h5>
        </div>

        <div class="category-item">
          <img src="/assets/img/cat-6.jpg" alt="Makijaż">
          <h5 class="text-dark text-center">Makijaż</h5>
        </div>

      </div>
    </div>

  </div>
</div>
