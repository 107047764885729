import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  constructor() { }

  orders: [];

  ngOnInit(): void {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      window.location.href = '/Login';
    }

    axios.get(environment.API_URL + 'my-orders', {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      }})
      .then(res => {
        this.orders = res.data.orders;
    
    });

  }

  DeleteOrder (id) {
    axios.delete(environment.API_URL + 'my-orders/' + id, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      }})
      .then(res => {
        console.log(res.data);

        Swal.fire({
          title: 'Sukces',
          text: 'Zamówienie zostało usunięte',
          icon: 'success',
          confirmButtonText: 'OK'
        });

        this.ngOnInit();
      }
    );
  }
}
