<div class="container-fluid mt-5 mb-5 popular-services">

  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-4">
        <h3 class="text-dark text-center">Najpopularniejsze usługi</h3>
        <p class="text-center"> w Poznaniu</p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-4 services-item">
        <a href="#">
          <div> Strzyżenie Grunwald</div>
          <div class="services-item--icon">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 7L1 7M17 7L11 13M17 7L11 1" stroke="#DFDFE6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
    </div>
  </div>

</div>
