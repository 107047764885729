<app-header></app-header>
<div style="padding-top:180px;"></div>


<div>
    <div class="card rounded text-dark mt-3" style="margin-left:auto; margin-right:auto; width: 85%;">
        <h2 class="p-5">
            Daj opinię 
        </h2>

            <div style="margin-left: auto;margin-right:auto; width: 90%;">        
                <div class="form-group">
                    <form action="">
                        <input type="text" placeholder="tytuł" class="p-2 rounded" style=" border: 1px black solid;" id="title">
                        <input type="number" placeholder="Ocena (liczba od 1 do 5)" class="p-2 rounded" style="margin-left: 10px; border: 1px black solid;" id="rating">
                        <textarea name="content" id="content" cols="30" rows="10" class="p-2 rounded mt-3" style="width: 90%; border: 1px black solid;" placeholder="Zawartość"></textarea>
                    </form>
                </div>

                
                <div class="mb-5 mt-3">
                    <button class="btn btn-primary p-1 m-1 w-50"  (click)="GiveFeedback()">
                        Wyślij
                    </button>
                </div>
            </div>
    </div>
</div>