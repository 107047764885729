import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-edition',
  templateUrl: './profile-edition.component.html',
  styleUrls: ['./profile-edition.component.scss']
})
export class ProfileEditionComponent implements OnInit {


  user: any;

  constructor() { }

  ngOnInit(): void {
    this.FetchProfile ();
  }

  public FetchProfile(): void {
    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }

    axios.get( environment.API_URL + 'user', config )
      .then( response => {
        this.user = response.data.success;
      })
  }

  public Save(): void {

    const name = (document.getElementById('name') as HTMLInputElement).value;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const phone_number = (document.getElementById('phone_number') as HTMLInputElement).value;
    const description = (document.getElementById('description') as HTMLInputElement).value;
    const city = (document.getElementById('city') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement).value;

    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }

    const postData = {
      name: name,
      email: email,
      phone_number: phone_number,
      description: description,
      city: city,
      password: password
    }

    axios.put( environment.API_URL + 'user', postData, config )
    .then( response => {
      swal.fire({
        title: 'Sukces!',
        text: 'Pomyślnie zaktualizowano profil',
        icon: 'success',
        confirmButtonText: 'OK'
      })
    })

    console.log(postData);
  }
  
}
