import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-items-extended-view',
  templateUrl: './items-extended-view.component.html',
  styleUrls: ['./items-extended-view.component.scss']
})
export class ItemsExtendedViewComponent implements OnInit {

  Offers: any;

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // get route params
    this.route.paramMap.subscribe((params: ParamMap) => {
      // get city from route params
      const city = params.get('location');
      
      axios.get(environment.API_URL + 'Offer/City/' + city)
        .then(function (response) {
          this.Offers = response.data.success;
          console.log(this.Offers);
        }.bind(this))
      })
  }
}
