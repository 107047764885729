import { Component, OnInit } from '@angular/core';
import axios from 'axios'
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-template-contact-form',
  templateUrl: './template-contact-form.component.html',
  styleUrls: ['./template-contact-form.component.scss']
})
export class TemplateContactFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.title = 'Skontaktuj się z nami - Pickmode';
  }

  SendForm () {
    // get data from form by getelemetbyid
    const name = (<HTMLInputElement>document.getElementById('name')).value;
    const surname = (<HTMLInputElement>document.getElementById('surname')).value;
    const email = (<HTMLInputElement>document.getElementById('email')).value;
    const details = (<HTMLInputElement>document.getElementById('details')).value;
    const info = (<HTMLInputElement>document.getElementById('info')).value;

    const config = {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('accessToken')
      }
    };

    const PostData = {
      name: name,
      surname: surname,
      email: email,
      details: details,
      info: info
    };

    // validate form for empty fields
    if (name === '' || surname === '' || email === '' || details === '') {
      Swal.fire({
        icon: 'info',
        title: 'Błąd',
        text: 'Prosimy wypełnić wszystkie pola'
      })
      return;
    }


    axios.post(environment.API_URL + 'Contact-Us', PostData, config)
      .then(res => {
        Swal.fire({
          title: 'Sukces!',
          text: 'Dziękujemy za wiadomość! Wkrótce się skontaktujemy.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          window.location.href = '/';
        });
      }).catch(err => {
        Swal.fire({
          title: 'Błąd!',
          text: err.response.data.message,
          icon: 'info',
          confirmButtonText: 'OK'
        })
      });
  }
}
