import { Component, Input, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-single-item-header',
  templateUrl: './single-item-header.component.html',
  styleUrls: ['./single-item-header.component.scss']
})
export class SingleItemHeaderComponent implements OnInit {

  constructor() { }

  @Input() title: any;
  @Input() phone: any;
  @Input() adres: any;
  @Input() image: string;
  @Input() id: any;
  @Input() comments: any;

  facebook: any;
  twitter: any;
  links: [];
  rating: any;

  ngOnInit(): void {
    // fimeout
    setTimeout(() => {
      this.FetchData();
    }
    , 1500);
  }
  
  FetchData () {
    let sum = 0;

    this.comments.forEach(comment => {
      sum += comment.rating;
    });

    this.rating = sum / this.comments.length;

  }


}
