import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import swal from 'sweetalert2';

declare var paypal;

@Component({
  selector: 'app-template-deposit',
  templateUrl: './template-deposit.component.html',
  styleUrls: ['./template-deposit.component.scss']
})
export class TemplateDepositComponent implements OnInit {

  checkout = true;

  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  product = {
    price: 0
  }


  offer: any;

  image: string;

  ngOnInit(): void {
    document.title = 'Wpłać depozyt - Pickmode';

    const offer_id = this.route.snapshot.paramMap.get('offer_id');

    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }


    axios.get(environment.API_URL + 'Offer/' + offer_id + '/CanIPlaceOrder', config)
      .then(function (response) {
        
      }.bind(this))
      .catch(function (error) {
        swal.fire({
          title: 'Błąd',
          text: 'Nie możesz złożyć zamówienia, ponieważ właściciel oferty nie akceptuje nowych klientów',
          icon: 'error',
          confirmButtonText: 'Ok'
        });

        this.router.navigate(['/']);
      }.bind(this));


    axios.get(`${environment.API_URL}Offer/${offer_id}`, config)
      .then(function (response) {
        this.GeneratePayment();

        console.log(response);
        this.offer = response.data.offer;

        if (this.offer.employees.length <= 0) {
          swal.fire({
            title: 'Błąd',
            text: 'Oferta nie jest poprawnie skonfigurowana',
            icon: 'error',
            confirmButtonText: 'OK'
          });

          this.location.back();
        }
        
        this.offer.employees.forEach(employee => {
          const url = environment.API_URL.substring(0, environment.API_URL.length - 4);
          employee.image = url + 'uploads/profile_images/' + employee.image

        });
        
        const baseUrl = environment.API_URL.substring(0, environment.API_URL.length - 4);

        this.image = baseUrl + 'OfferImage/' + response.data.offer.image.image
      }.bind(this))

  }

  ChangeEmployee(employee) {
    this.offer.employees.forEach(element => {
      element.checked = false;
      element.border = 'none';
    });

    employee.checked = !employee.checked;
    employee.border = employee.checked ? '1.5px solid #000000' : '1px solid #00000';

    if (!employee.checked) {
      employee.border = ''
    }
  }
   
  PlaceOrder () {


    const time = (document.getElementById('time') as HTMLInputElement).value;
    const date = (document.getElementById('date') as HTMLInputElement).value;

    const employees = this.offer.employees.filter(employee => employee.checked);
    if (employees.length === 0) {
      swal.fire({
        title: 'Błąd',
        text: 'Nie wybrano pracownika',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    if (!date) {
      swal.fire({
        title: 'Błąd',
        text: 'Nie wybrano daty',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    if (!time) {
      swal.fire({
        title: 'Błąd',
        text: 'Nie wybrano godziny',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    const day = new Date(date).getDay()-1;

    // search hours in array where day is equal to selected day
    const hours = this.offer.hours.filter(hour => hour.day_of_week === day)[0];

    console.log(hours);

    
    if (time < hours.start_time || time > hours.end_time) {
      swal.fire({
        title: 'Błąd',
        text: 'Wybrana godzina nie jest w zakresie pracy lokalu',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    // if date is in past
    const dateNow = new Date();
    const dateSelected = new Date(date);
    if (dateSelected < dateNow) {
      swal.fire({
        title: 'Błąd',
        text: 'Wybrana data jest w przeszłości',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }

    
    
    document.getElementById("checkout").style.display = "block";
    document.getElementById("configuration").style.display = "none";
  }

  GeneratePayment () {
    paypal
    .Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: 'PLN',
                value: Math.round(this.offer.price * this.offer.deposit)
              }
            }
          ]
        });
      },
      onApprove: async (data, actions) => {
        
        const time = (document.getElementById('time') as HTMLInputElement).value;
        const date = (document.getElementById('date') as HTMLInputElement).value;
        const employees = this.offer.employees.filter(employee => employee.checked);

        axios.post(environment.API_URL + 'place-order/' + this.route.snapshot.paramMap.get('offer_id'), {
          date: date,
          start_time: time,
          offer_id: this.route.snapshot.paramMap.get('offer_id'),
          employee_id: employees[0].id,
          payer_id: data.payerID,
          payment_id: data.orderID,
          payment_token: data.paymentToken,
          amount: Math.round(this.offer.price * this.offer.deposit)
        },
        {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
          }
        }).then(function (response) {
          console.log(response);
          swal.fire({
            title: 'Sukces, zapłacono!',
            text: 'Zapłacono pomyślnie, dziękujemy za zapłatę!',
            icon: 'success',
            confirmButtonText: 'Zamknij'
          }).then(() => {
            this.router.navigate(['/Zamowienia']);
          });
        }).catch(function (error) {
          if (error.response.status === 401) {
            swal.fire({
              title: 'Błąd',
              text: 'Właściciel oferty nie przyjmuje nowych zamówień',
              icon: 'error',
              confirmButtonText: 'Ok'
            });
          }
          // refund payment
          actions.order.refund({
            amount: {
              currency_code: 'PLN',
              value: Math.round(this.offer.price * this.offer.deposit)
            }
          });
        });
      }
    }).render(this.paypalElement.nativeElement);
  }
}
