<app-header></app-header>
<div class="empty-space" style="padding-top: 200px;"></div>
<app-items-search-by-tag image=""></app-items-search-by-tag>

<div class="container-fluid mt-5 mb-5 seo-content">

  <div class="container mt-5">

    <div class="row d-flex justify-content-center">
      <div class="col-lg-4">
        <h3 class="text-dark text-center">Skup się na tym, co ważne.
          My zajmiemy się resztą...</h3>
      </div>
    </div>

    <div class="row d-flex justify-content-center mt-5">
      <div class="col-lg-4 text-center text-lg-start">
        <p>Chcesz umówić się na wizytę do fryzjera, barbera, stylistki paznokci lub salonu masażu w okolicy? Szukasz miejsca, w którym specjaliści zadbają o Twoją brodę, brwi lub zrobią masaż tkanek głębokich?        </p>
      </div>
      <div class="col-lg-4 text-center text-lg-start">
        <p>Pickmode to darmowa aplikacja do rezerwacji, dzięki której z łatwością znajdziesz wolny termin u specjalisty i wygodnie umówisz się na wizytę. Bez dzwonienia - rezerwujesz o każdej porze i z dowolnego miejsca.  Odkrywaj nowe miejsca w swojej okolicy i rezerwuj od razu z Pickmode
        </p>
      </div>
    </div>

  </div>


</div>

<app-footer></app-footer>


