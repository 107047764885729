<app-header></app-header>
<div class="empty-space" style="padding-top: 180px;"></div>

<div>
    <div class="card rounded text-dark mt-3" style="margin-left:auto; margin-right:auto; width: 85%;">
        <div class="order d-flex justify-content-between p-2 mt-2" style="align-items: center;" *ngFor="let order of orders">
            
            <h4 role="button">
                <span class="text-dark">
                    <span class="">
                        Zamówienie nr: {{order.id}}
                    </span>
                    <span class="hide_if_mobile"> 
                        | data: {{order.created_at}}
                    </span>
                </span>
            </h4>

            <div>
              <button class="btn btn-danger text-dark p-1"  (click)="DeleteOrder(order.id)">
                Anuluj
              </button>

              <a routerLink="/Daj-opinie/{{ order.offer.id }}" class="btn btn-primary p-1 m-1"  >
                Oceń
              </a>

            </div>
        
        </div>

        <div class="text-align-center p-5" *ngIf="orders.length == 0 ">
            <h1>
                Brak zamówień
            </h1>
        </div>

    </div>
</div>