<div class="container-fluid single-item-content">
  <div class="container">

    <!--o nas-->
    <div class="row mt-3">
      <div class="col">
        <h3>O nas</h3>
        <p [innerHtml]="description" >
          <!------- Ofer description -------->
        </p>
      </div>
    </div>

    <!--nasi pracownicy-->
    <div class="row mt-3">
      <div class="col">
        <h3>Nasi pracownicy</h3>

        <div class="persons">
          <div *ngFor="let employee of employees" class="person">
            <img src="{{ employee.image }}" alt="" style="width: 100px; height: 100px; border-radius: 100000px;">
            {{ employee.user.name }}
          </div>
        </div>

      </div>
    </div>

    <!--godziny otwarcia-->
    <div class="row mt-3 opening-hours">
      <div class="col">
        <h3>Godziny otwarcia</h3>
        <ul>
          <div *ngFor="let date of offer.hours">
            <li>
              <div>
                <span class="name" *ngIf="date.day_of_week == 0">Poniedziałek</span>
                <span class="name" *ngIf="date.day_of_week == 1">Wtorek</span>
                <span class="name" *ngIf="date.day_of_week == 2">Środa</span>
                <span class="name" *ngIf="date.day_of_week == 3">Czwartek</span>
                <span class="name" *ngIf="date.day_of_week == 4">Piątek</span>
                <span class="name" *ngIf="date.day_of_week == 5">Sobota</span>
                <span class="name" *ngIf="date.day_of_week == 6">Niedziela</span>

                <span class="hours closed">{{ date.start_time }} - {{ date.end_time }}</span>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>

    <a *ngIf="offer.deposit == null "  routerLink="/Zloz-zamowienie/{{id}}" class="p-2 rounded" style="background-color: rgb(94, 94, 165); color: aliceblue;" >Zloz zamowienie</a>
    
    <a *ngIf="offer.deposit != null "  routerLink="/Wplac-zadatek/{{id}}" class="p-2 rounded" style="background-color: rgb(94, 94, 165); color: aliceblue;" >Zloz zamowienie (Wymaga wpłaty zadatku)</a>
  
  </div>
</div>
